import * as accountManager from '../../store/states/account-manager.state';
import { AccountManagerAction, AccountManagerActionType } from '../../store/actions/account-manager.actions';

export function accountManagerReducer(state = accountManager.initialStateAccountManager, action: AccountManagerAction | any): accountManager.StateAccountManager {
  switch (action.type) {
    case AccountManagerActionType.Loading: {
      return { ...state, loading: true };
    }
    case AccountManagerActionType.LoadSuccess: {
      return accountManager.accountManagerAdapter.setAll(action.payload.collaborators, {
        ...state,
        error: false,
        loading: false
      });
    }
    case AccountManagerActionType.LoadFailure: {
      return accountManager.accountManagerAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}
