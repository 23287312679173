import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Buys } from '../../models/buys/buys';

export interface State extends EntityState<Buys> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const buysAdapter: EntityAdapter<Buys> = createEntityAdapter<Buys>({
  selectId: (buys: Buys) => buys.position
});

export const initialState: State = buysAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
