export const arrayDemo = [
  {
    nombre: 'Test 1',
  },
  {
    nombre: 'Test 2',
  },
  {
    nombre: 'Test 3',
  },
];

export const ivaSelect = [
  {
    nombre: 'Iva 10.5%',
    value: 0.105
  },
  {
    nombre: 'Iva 21%',
    value: 0.21
  },
  {
    nombre: 'Iva 2.5%',
    value: 0.025
  },
  {
    nombre: 'Iva 27%',
    value: 0.27
  },
  {
    nombre: 'Iva 5',
    value: 0.05
  },
  {
    nombre: 'Iva Exento',
    value: 0
  },
]

export const paymentCondition = [
  {
    id: 1,
    nombre: 'Cuenta Corriente',
  },
  {
    id: 2,
    nombre: 'Contado',
  },
]

export const currency = [
  {
    id: -2,
    codigo: 'PESOS_ARGENTINOS',
    nombre: 'Pesos Argentinos',
    ISOCurrencyCode: 'ARS',
  },
  {
    id: -3,
    codigo: 'DOLARES',
    nombre: 'Dólares',
    ISOCurrencyCode: 'USD',
  },
]

export const transactionType = [
  {
    id: 1,
    nombre: 'Factura',
  },
  {
    id: 2,
    nombre: 'Nota de Débito',
  },
  {
    id: 3,
    nombre: 'Nota de Crédito',
  },
  {
    id: 6,
    nombre: 'Recibo',
  },
  {
    id: 8,
    nombre: 'Extracto Bancario',
  },
  {
    id: 99,
    nombre: 'Otros Comprobantes',
  },
]

export const transactionTypeSales = [
  {
    id: 1,
    nombre: 'Factura',
  },
  {
    id: 10,
    nombre: 'Factura de Crédito MiPyme',
  },
]

export const taxPerception = [
  {
    id: -31,
    codigo: 'IVA',
    nombre: 'IVA',
  },
  {
    id: -129,
    codigo: 'GANANCIAS',
    nombre: 'Ganancias',
  },
  {
    id: -33,
    codigo: 'INGRESOS_BRUTOS_CORDOBA_PERCEPCION',
    nombre: 'Ingresos Brutos Córdoba (Percepción)',
  },
  {
    id:2350703,
    codigo:'IMPUESTO_COMERCIO_E_INDUSTRIA',
    nombre:'Impuesto Comercio e Industria',
 }
]