<mat-card>
    <mat-card-content>
        <span routerLink="/admin/" (click)="onSidenavClose()">
            DinoCount
        </span>
        <mat-icon (click)="onSidenavClose()">
            close
        </mat-icon>
    </mat-card-content>
</mat-card>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <span>
                {{node.name}}
            </span>
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_less' : 'expand_more'}}
            </mat-icon>
        </button>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <!-- <button mat-icon-button disabled></button>  -->
        <a class="link-menu" mat-flat-button routerLink="/admin/{{node.link}}" (click)="onSidenavClose()">
            {{node.name}}
        </a>
    </mat-tree-node>
</mat-tree>