import * as sales  from '../../store/states/sales.states';
import { SalesAction, SalesActionType } from '../../store/actions/sales.actions';

export function salesReducer(state = sales.initialStateSales, action: SalesAction | any): sales.StateSales {
  switch (action.type) {
    case SalesActionType.Loading: {
      return { ...state, loading: true };
    }
    case SalesActionType.LoadSuccess: {
      return sales.salesAdapter.setAll(action.payload.sales, {
        ...state,
        error: false,
        loading: false,
        total: action.payload.total,
      });
    }
    case SalesActionType.LoadFailure: {
      return sales.salesAdapter.removeAll({
        ...state,
        error: true,
        loading: false,
        total: 0,
      });
    }
    default:
      return state;
  }
}
