import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { SelectionModel } from '@angular/cdk/collections';
import { Store, select } from '@ngrx/store';
import { Observable, merge, Subject, Subscription } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

// NgRx
import * as sales from 'src/app/store/selectors/sales.selectos';
import { Sales } from 'src/app/models/sales/sales';
import { GlobalState } from 'src/app/store/states/global.state';
import { SalesLoadAction } from 'src/app/store/actions/sales.actions';
import { SalesParams } from 'src/app/models/sales/sales-params';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild('tableSales') table?: ElementRef;

  displayedColumns: string[] = [
    'select',
    'position',
    'cuil',
    'fullname',
    'type',
    'price',
    'date',
    'costs',
  ];

  btn: any = [];
  dataSource!: MatTableDataSource<Sales>;
  selection = new SelectionModel<Sales>(true, []);
  salesTotal?: number;
  noData: Sales[] = [<Sales>{}];
  loading?: boolean;
  public error$?: Observable<boolean>;
  public filterSubject = new Subject<string>();
  defaultSort: Sort = { active: 'role', direction: 'asc' };

  private filter: string = '';
  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>
  ) {
    //
  }

  public ngOnInit(): void {
    this.store.select((state) => state.sales.loading);
    this.store.pipe(select(sales.selectAllSales)).subscribe((sales) => this.initializeData(sales));
    this.store.pipe(select(sales.selectSalesTotal)).subscribe((total) => (this.salesTotal = total));
    this.subscription.add(
      this.store
        .pipe(select(sales.selectSalesLoading))
        .subscribe((loading) => {
          if (loading) {
            this.dataSource = new MatTableDataSource(this.noData);
          }
          this.loading = loading;
        })
    );
    this.error$ = this.store.pipe(select(sales.selectSalesError));
  }

  public ngAfterViewInit(): void {
    this.loadSales();
    let filter$ = this.filterSubject.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap((value: string) => {
        this.paginator.pageIndex = 0;
        this.filter = value;
      })
    );

    let sort$ = this.sort.sortChange.pipe(
      tap(() => (this.paginator.pageIndex = 0))
    );

    this.subscription.add(
      merge(filter$, sort$, this.paginator.page)
        .pipe(tap(() => this.loadSales()))
        .subscribe()
    );
  }

  private loadSales(): void {
    this.store.dispatch(
      new SalesLoadAction(<SalesParams>{
        filter: this.filter.toLocaleLowerCase(),
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        sortDirection: this.sort.direction,
        sortField: this.sort.active,
      })
    );
  }

  private initializeData(sales: Sales[]): void {
    this.dataSource = new MatTableDataSource(
      sales.length ? sales : this.noData
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public retry(): void {
    this.loadSales();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: Sales): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
