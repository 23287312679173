import * as invoice from '../../store/states/invoice.state';
import { InvoiceAction, InvoiceActionType } from '../../store/actions/invoice.actions';

export function invoiceReducer(state = invoice.initialStateInvoice, action: InvoiceAction | any): invoice.StateInvoice {
  switch (action.type) {
    case InvoiceActionType.Loading: {
      return { ...state, loading: true };
    }
    case InvoiceActionType.LoadingGetInvoice: {
      return { ...state, loadingGetInvoice: true };
    }
    case InvoiceActionType.LoadSuccess: {
      return  {
        ...state,
        error: false,
        invoices: action.payload.invoices,
        loading: false
      };
    }
    case InvoiceActionType.LoadSuccessGet: {
      return  {
        ...state,
        errorGet: false,
        getInvoices: action.payload.invoices,
        loadingGetInvoice: false
      };
    }
    case InvoiceActionType.LoadFailure: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case InvoiceActionType.LoadFailureGet: {
      return {
        ...state,
        errorGet: true,
        loadingGetInvoice: false,
      };
    }
    default:
      return state;
  }
}