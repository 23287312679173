import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { SalesProductsService } from 'src/app/services/service.index';
import { SalesProductsParams } from 'src/app/models/sales-products/sales-products-params';
import { SalesProductsResponse } from 'src/app/models/sales-products/sales-products-response';
import * as salesProducts from '../actions/sales-products.actions';

@Injectable()
export class SalesProductsEffects {
  constructor(
    private _salesProductsService: SalesProductsService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadSalesProducts$ = createEffect(() =>
    this.actions$.pipe(
        ofType<salesProducts.SalesProductsLoadAction>(salesProducts.SalesProductsActionType.Loading),
        map((action) => action.payload),
        switchMap((params) =>
        this._salesProductsService.getAllSalesProducts().pipe(
          map((response : any) => new salesProducts.SalesProductsLoadSuccessAction(response)),
          catchError((error) => of(new salesProducts.SalesProductsLoadFailAction(error)))
        )
      )
    )
  );
}