import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalState } from 'src/app/store/states/global.state';
import { Store } from '@ngrx/store';

import { BuysParams } from 'src/app/models/buys/buys-params';
import { BuysLoadAction } from 'src/app/store/actions/buys.actions';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
  public fromDate : Date;
  public toDate : Date;

  public formFilter = new FormGroup({
    fromDate: new FormControl(null),
    toDate: new FormControl(null),
  });

  constructor(public store: Store<GlobalState>) {
    this.toDate = this.formFilter.value.toDate;
    this.fromDate = this.formFilter.value.fromDate;

    this.formFilter.valueChanges.subscribe((x) => {
      /**
       * Filter by between dates
       */
      this.fromDate = this.formFilter.value.fromDate;
      this.toDate = this.formFilter.value.toDate;
   
      this.store.dispatch(
        new BuysLoadAction(<BuysParams>{
          filter: '',
          pageIndex: 0,
          pageSize: 10,
          sortDirection: 'asc',
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
      );
    });
  }

  ngOnInit(): void {
    //
  }
}
