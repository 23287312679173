import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import {
  SharedService,
  BuysService,
  CustomersService,
  ProvidersService,
  PurchaseProductsService,
  SalesService,
  SalesPointsService,
  SalesProductsService,
  AccountsService,
  AccountManagerService,
  InvoiceService,
  NotificationService,
  TokenResolverService,
  AuthService
} from "./service.index";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    SharedService,
    BuysService,
    CustomersService,
    ProvidersService,
    PurchaseProductsService,
    SalesService,
    SalesPointsService,
    SalesProductsService,
    AccountsService,
    AccountManagerService,
    InvoiceService,
    NotificationService,
    TokenResolverService,
    AuthService
  ]
})
export class ServiceModule { }
