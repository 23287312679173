
import { Action } from '@ngrx/store'
import { PurchaseProductsParams } from '../../models/purchase-products/purchase-products-params';
import { PurchaseProductsResponse } from '../../models/purchase-products/purchase-products-response';

export enum PurchaseProductsActionType {
  Loading = '[PurchaseProducts] Loading',
  LoadSuccess = '[PurchaseProducts] LoadSuccess',
  LoadFailure = '[PurchaseProducts] LoadFailure'
}

export class PurchaseProductsLoadAction implements Action {
  public readonly type = PurchaseProductsActionType.Loading;
  constructor(public payload: PurchaseProductsParams) {}
}

export class PurchaseProductsLoadSuccessAction implements Action {
  public readonly type = PurchaseProductsActionType.LoadSuccess;
  constructor(public payload: PurchaseProductsResponse) {}
}

export class PurchaseProductsLoadFailAction implements Action {
  public readonly type = PurchaseProductsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type PurchaseProductsAction = PurchaseProductsLoadAction | PurchaseProductsLoadSuccessAction | PurchaseProductsLoadFailAction;