import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../services/service.index';
import Swal from 'sweetalert2';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private _authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                let pipeMsj = error.error.message || error.error.mensaje || error.error.error;
                if(error.status != 401 && error.status != 0 && pipeMsj != 'invalid_grant'){
                    setTimeout(() => {
                        Swal.fire({
                            title: `Error - ${error.status}`,
                            text: pipeMsj,
                            icon: 'error',
                            showConfirmButton: true,
                            width: 300,
                        })
                    }, 500);
                    return throwError(pipeMsj);
                } else {
                    return next.handle(request);
                }
            })
        )
    }

}