import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface MenuNode {
  name: string;
  link: string;
  children?: MenuNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  link: string;
  level: number;
}

/**
 * Tree menu data
 */
const TREE_DATA: MenuNode[] = [
  {
    name: 'Cargar comprobantes',
    link: '',
    children: [
      { 
        name: 'Comprobantes compra',
        link: 'receipt',
      },
      { 
        name: 'Comprobantes venta',
        link: 'receipt-sale', 
      },
    ]
  }, 
  {
    name: 'Historial de Comprobantes',
    link: 'voucher-history',
  }, 
  {
    name: 'Sueldos',
    link: '',
    children: [
      { 
        name: 'Carga de sueldos',
        link: 'salaries',
      },
      { 
        name: 'Planillas cargadas',
        link: 'salaries-sheetload', 
      },
    ]
  }, 
  {
    name: 'Impuestos',
    link: '',
    children: [
      { 
        name: 'Carga de impuestos',
        link: 'taxes',
      },
      { 
        name: 'Planillas cargas',
        link: 'taxes-sheetload',
      },
    ]
  }, 
  {
    name: 'Notificaciones',
    link: '',
  }, 
];

@Component({
  selector: 'app-sidenav-left',
  templateUrl: './sidenav-left.component.html',
  styleUrls: ['./sidenav-left.component.scss']
})
export class SidenavLeftComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  ngOnInit(): void {
    // 
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      link: node.link,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
