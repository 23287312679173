import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { BuysService } from 'src/app/services/service.index';
import { BuysParams } from 'src/app/models/buys/buys-params';
import { BuysResponse } from 'src/app/models/buys/buys-response';
import * as buys from '../actions/buys.actions';

@Injectable()
export class BuysEffects {
  constructor(
    private _buysService: BuysService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadBuys$ = createEffect(() =>
    this.actions$.pipe(
      ofType<buys.BuysLoadAction>(buys.BuysActionType.Loading),
      map((action) => action.payload),
      switchMap((params: BuysParams) =>
        this._buysService.getBuys(params).pipe(
          map((response: BuysResponse) => new buys.BuysLoadSuccessAction(response)),
          catchError((error) => of(new buys.BuysLoadFailAction(error)))
        )
      )
    )
  );
}
