<ng-template #normal>
    <button mat-raised-button [ngClass]="icon === 'delete' ? 'simple-danger' : 'simple-primary'" [disabled]="status" (click)="icon == 'edit' ? editVoucher() : null || icon == 'delete' ? deleteItem() : null">
        <mat-icon>{{icon}}</mat-icon>
        {{name}}
    </button>
</ng-template>

<ng-container *ngIf="icon === 'file_download'; else normal">
    <button class="simple-primary" mat-raised-button [disabled]="status" [matMenuTriggerFor]="optionsDownload">
        <mat-icon>{{icon}}</mat-icon>
        {{name}}
    </button>
    <!-- Show options download -->
    <mat-menu #optionsDownload="matMenu" [overlapTrigger]="false">
        <button mat-menu-item style="white-space: normal">
            .csv
        </button>
        <button mat-menu-item (click)="exportAsExcel()">
            .xls
        </button>
    </mat-menu>
</ng-container>