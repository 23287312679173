import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private titleSection : string = '';
  private titleSection$ : Subject<string>;
  private rowMenu$ : Subject<boolean>;
  private backMenu$ : Subject<boolean>;
  private detailOrder$ : Subject<boolean>;
  private statusLefBar$ : Subject<boolean>;

  constructor() {
    this.titleSection$ = new Subject();
    this.rowMenu$ = new Subject();
    this.backMenu$ = new Subject();
    this.detailOrder$ = new Subject();
    this.statusLefBar$ = new Subject();
  }

  /**
   * Status and title page
   */
  changeTitile(title: string){
    this.titleSection = title;
    this.titleSection$.next(this.titleSection);
  }

  getTitleSection$(): Observable<string>{
    return this.titleSection$.asObservable();
  }

  changeStatusRowMenu(status: boolean, backMenu: boolean = false){
    this.rowMenu$.next(status);
    this.backMenu$.next(backMenu);
  }

  getStatusRowMenu$(): Observable<boolean>{
    return this.rowMenu$.asObservable();
  }

  getStatusBackMenu$(): Observable<boolean>{
    return this.backMenu$.asObservable();
  }

  /**
   * Info invoice or cost 
   */
  changeDetailOrder(data:any){;
    this.detailOrder$.next(data);
  }

  getDetailOrder$(): Observable<boolean>{
    return this.detailOrder$.asObservable();
  }

  /**
   * Status Left bar Provider
   */
  changeStatusLeftBar(data:any){;
    this.statusLefBar$.next(data);
  }

  getStatusLeftBar$(): Observable<boolean>{
    return this.statusLefBar$.asObservable();
  }
}
