import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from 'rxjs/operators';
import { CostService } from 'src/app/services/service.index';
import { CostParams } from 'src/app/models/cost/cost-params';
import { CostResponse } from 'src/app/models/cost/cost-response';
import * as cost from '../actions/cost.actions';

// Services
import { NotificationService } from 'src/app/services/service.index';

@Injectable()
export class CostEffects {
  constructor(
    private actions$: Actions,
    private _costService: CostService,
    private _notifyService: NotificationService
  ) {
    //
  }

  public loadCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType<cost.CostLoadAction>(cost.CostActionType.Loading),
      map((action) => action.payload),
      switchMap((params: CostParams) =>
        this._costService.postCost(params.body,params.skipXubio,params.transaccionid).pipe(
          map(
            (response: any) => {
              if(Object.keys(response.costs).length){
                this._notifyService.showSuccess("El comprobante se ha cargado con exito!", "Cost");
              }else{
                this._notifyService.showError("Error al enviar datos", "Cost");
              }
              return new cost.CostLoadSuccessAction(response)
            }
          ),
          catchError((error) => of(new cost.CostLoadFailAction(error)))
        )
      )
    )
  );

  public getCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType<cost.CostLoadAction>(cost.CostActionType.LoadingGetCost),
      map((action) => action.payload),
      switchMap((params: CostParams) =>
        this._costService.getCost(params.body).pipe(
          map(
            (response: any) => {
              if(Object.keys(response.costs).length){
                this._notifyService.showSuccess("Datos obtenidos", "Cost");
              }else{
                this._notifyService.showError("Error al obtener datos", "Cost");
              }
              return new cost.CostLoadSuccessGetAction(response)
            }
          ),
          catchError((error) => of(new cost.CostLoadGetFailAction(error)))
        )
      )
    )
  );
}
