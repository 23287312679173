import { Injectable } from '@angular/core';
import { CONFIG } from "../../config/config";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public http: HttpClient,
    public router: Router,
  ) {
    // 
  }

  logout() {
    this.removeSessionStorage();
    this.router.navigate(['/login']);
  }
  
  removeSessionStorage() {
    localStorage.removeItem('token');
  }

  getTokenStorage() {
    return localStorage.getItem('token');
  }

  getRefreshTokenStorage() {
    return localStorage.getItem('refresh_token');
  }

  isLogin() {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }
}
