
import { Action } from '@ngrx/store'
import { BuysParams } from '../../models/buys/buys-params';
import { BuysResponse } from '../../models/buys/buys-response';

export enum BuysActionType {
  Loading = '[Buys] Loading',
  LoadSuccess = '[Buys] LoadSuccess',
  LoadFailure = '[Buys] LoadFailure'
}

export class BuysLoadAction implements Action {
  public readonly type = BuysActionType.Loading;
  constructor(public payload: BuysParams) {}
}

export class BuysLoadSuccessAction implements Action {
  public readonly type = BuysActionType.LoadSuccess;
  constructor(public payload: BuysResponse) {}
}

export class BuysLoadFailAction implements Action {
  public readonly type = BuysActionType.LoadFailure;
  constructor(public error: any) {}
}

export type BuysAction = BuysLoadAction | BuysLoadSuccessAction | BuysLoadFailAction;
