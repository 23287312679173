import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateSalaries, salariesAdapter } from '../../store/states/salaries.state';

export const {
  selectIds: _selectSalariesDataIds,
  selectEntities: _selectSalariesEntities,
  selectAll: _selectAllSalaries,
  selectTotal: _selectSalariesTotal
} = salariesAdapter.getSelectors();

export const selectState = createFeatureSelector<StateSalaries>('salaries');

export const selectStateSalaries =
  createFeatureSelector<StateSalaries>('salaries');

export const selectSalariesImport = createSelector(
  selectStateSalaries,
  (stateSalaries: StateSalaries): any => stateSalaries.salaries
);

export const selectSalariesError = createSelector(
  selectStateSalaries,
  (stateSalaries: StateSalaries): boolean => stateSalaries.error
);

export const selectSalariesLoading = createSelector(
  selectStateSalaries,
  (stateSalaries: StateSalaries): boolean => stateSalaries.loading
);
