import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { CostGetAction } from 'src/app/store/actions/cost.actions';
import { CostParams } from 'src/app/models/cost/cost-params';

import { InvoiceGetAction } from 'src/app/store/actions/invoice.actions';
import { InvoiceParams } from 'src/app/models/invoice/invoice-params';

import * as providers from 'src/app/store/selectors/providers.selectors';
import * as customers from 'src/app/store/selectors/customers.selectors';
import * as invoice from 'src/app/store/selectors/invoice.selectors';
import * as cost from 'src/app/store/selectors/cost.selectors';

// Services
import { SharedService, NotificationService } from 'src/app/services/service.index';

// Hardcoded values
import { clientes } from 'src/hardcoded-values/provider/clientes';
import { proveedores } from 'src/hardcoded-values/provider/proveedores';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit {
  @Input() type?: number; // 1 - Customer | 2 - Providers
  @Input() open?: boolean = true;
  @HostBinding('class.hide') openLeftBar: boolean = false;

  form: FormGroup;

  placeholder: string = '';
  itemSelect: any = [];
  
  loading?: boolean;
  public error$?: Observable<boolean>;
  
  public loadingGet?: Observable<any>;
  errorGet?: boolean;
  public errorGet$?: Observable<boolean>;
  
  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private formBuilder: FormBuilder,
    private _sharedService : SharedService,
    private _notifyService : NotificationService
  ) {
    /**
     * Set status left bar
     */
     this._sharedService.getStatusLeftBar$().subscribe((statusLeftBar) => {
      this.openLeftBar = statusLeftBar;
    });

    /**
     * Generate form
     */
     this.form = this.formBuilder.group({
      vendorId: [''],
      clientId: [''],
      docNumber: [''],
    });
  }

  ngOnInit(): void {
    /**
     * Set array Customer or Provider 
     * accordinf to param.
     */
    switch (this.type) {
      case 1:
        this.placeholder = 'Cliente';
        // Get Customers
        this.subscription.add(
          this.store.pipe(select(customers.selectCustomersLoading)).subscribe((loading) => {
            if(loading){
              this.store.pipe(select(customers.selectAllCustomers)).subscribe((customers) => (this.itemSelect = customers));
            }
            this.loading = loading;
          })
        );
        this.error$ = this.store.pipe(select(customers.selectCustomersError));
        break;
      case 2:
        this.placeholder = 'Proveedor';
        // Get Providers
        this.subscription.add(
          this.store.pipe(select(providers.selectProvidersLoading)).subscribe((loading) => {
            if(loading){
              this.store.pipe(select(providers.selectAllProviders)).subscribe((providers) => (this.itemSelect = providers));
            }
            this.loading = loading;
          })
        );
        this.error$ = this.store.pipe(select(providers.selectProvidersError));
        break;
      default:
        break;
    }
  }

  /**
   * Customer Selected
   */
   userSelected(e:any){
    switch (this.type) {
      case 1:
        // Get id customer
       this.form.controls['clientId'].setValue(e.cliente_id);
      break;
      case 2:
        // Get id provider
        this.form.controls['vendorId'].setValue(e.proveedorid);
      break;
    default:
      break;
    }
  }

  /**
   * Import data
   */
  import() {
    switch (this.type) {
      case 1:
        // Get invoice by customer
        this.store.dispatch(new InvoiceGetAction(<InvoiceParams>{body : this.form.value}));
        this.store.pipe(select(invoice.selectInvoiceGetLoading)).subscribe((loading) => { 
          if(loading){
            this.store.pipe(select(invoice.selectGetInvoiceResponse)).subscribe((invoices) => (
              this._sharedService.changeDetailOrder(invoices)
            ));
          }
          this.loading = loading 
        })         
      break;
      case 2:
        // Get cost by provider
        this.store.dispatch(new CostGetAction(<CostParams>{body : this.form.value}));
        this.store.pipe(select(cost.selectCostGetLoading)).subscribe((loading) => { 
          if(loading){
            this.store.pipe(select(cost.selectGetCostResponse)).subscribe((costs) => (
              this._sharedService.changeDetailOrder(costs)
            ));
          }
          this.loading = loading 
        })
      break;
    default:
      break;
    }
  }

  /**
   * Momentarily reload page but in the future 
   * remplace for suscription
   */
   reloadCurrentRoute(){
      window.location.reload();
   }
}
