import {
  Component,
  OnInit,
  Input,
  HostListener,
  HostBinding,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subject } from 'rxjs';

import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { SalariesLoadImportAction } from 'src/app/store/actions/salaries.actions';

import * as XLSX from 'xlsx';
import * as moment from 'moment';

// Services
import { NotificationService } from 'src/app/services/service.index';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss'],
})
export class DragDropComponent implements OnInit {
  public status: string = 'show'; // Hidden
  public files: any[] = [];

  public spinnerEnabled = false;
  public keys?: string[];
  public dataSheet = new Subject();
  public isExcelFile?: boolean;

  @Input() type: any = [];
  @ViewChild('fileDropRef') fileDropRef?: ElementRef;

  constructor(
    private _notifyService: NotificationService,
    public store: Store<GlobalState>
  ) {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * On file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event.files);
    this.fileConvertJSON($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any, $event: any) {
    this.prepareFilesList(files);
    this.fileConvertJSON($event.target);
  }

  /**
   * Convert to JSON
   */
  fileConvertJSON(evt: any) {
    let data: any, header;
    const target: DataTransfer = <DataTransfer>evt;
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    if (target.files.length > 1) {
      this.fileDropRef.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      this.spinnerEnabled = true;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        let newData: any = [];
        data = XLSX.utils.sheet_to_json(ws);

        switch (this.type) {
          case 'salaries':
            data.forEach((element: any) => {
              newData.push({
                cuil: element['CUIL'],
                fullname: element['Apellido y Nombre'],
                type: element['Moneda'],
                // price: element['Costo Total'].toLocaleString('de-DE', {minimumFractionDigits: 2}),
                price: element['Costo Total'],
                date: moment(element['FECHA']).format('YYYY-MM-DD'),
                costs: element['Centro de Costos'],
              });
            });
            let sendSalaries: any = [{
              newData: newData,
              data: data
            }];
            this.store.dispatch(new SalariesLoadImportAction(sendSalaries));
            break;
          case 'taxes':
            data.forEach((element: any) => {
              newData.push({
                date: moment(element['FECHA']).format('YYYY-MM-DD'),
                detail: element['Detalle Impuesto'],
                period: moment(element['Período']).format('MMM-YY').toUpperCase().replace(/\./g,''),
                type: element['Moneda'],
                // price: element['Costo Total'].toLocaleString('de-DE', {minimumFractionDigits: 2}),
                price: element['Costo Total'],
                costs: element['Centro de Costo'],
              });
            });
            this.store.dispatch(new SalariesLoadImportAction(newData));
            break;
          case 'invoices':
            newData = [...data.reduce((r : any, o : any) => {
              const key = o.CLIENTE + o.INVOICEID;
              
              const item = r.get(key) || {
                client : o['CLIENTE'],
                accounts : [],
                totalAmount : 0,
                totalVat : 0,
                dueDate: moment(o['FECHA VENCIMIENTO']).format('YYYY-MM-DD'),
                issueDate: moment(o['FECHA EMISION']).format('YYYY-MM-DD'),                
                currency: o['CURRENCY'],
                xubio: o['XUBIO'],
                invoiceid: o['INVOICEID'],
                transactionType: o['TRANSACTION TYPE'],
                salesPoint: o['SALES POINT'],
                paymentCondition: o['PAYMENT CONDITION '],
                exchangePrice: o['EXCHANGE PRICE'],
                description: o['DESCRIPTION']
              }
               
              item.totalAmount += o['MONTO NETO'];
              item.totalVat += o['IVA'];
              item.accounts.push({
                account: o['CUENTA'],
                product: o['PRODUCTO'],
                detail: o['DETALLE '],
                costs: o['CENTRO COSTO'],
                price: o['MONTO NETO'],
                vat: o['IVA'],
                productCount: o['PRODUCT COUNT'],
                totalAmount: (o['PRODUCT COUNT'] * o['MONTO NETO']) + o['IVA'],
              })
            
              return r.set(key, item);
            }, new Map).values()];
            
            let senData: any = [{
              newData: newData,
              data: data
            }];
            this.store.dispatch(new SalariesLoadImportAction(senData));
            break;
          default:
            break;
        }
      };
      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.spinnerEnabled = false;
        this.keys = Object.keys(data[0]);
        this.dataSheet.next(data);
      };
    } else {
      this.fileDropRef.nativeElement.value = '';
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index]) {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
              this.openSnackBar();
              this.files.splice(index, 1);
              this.status = 'hidden';
            } else {
              this.files[index].progress += 5;
            }
          }
        }, 0); // 200
      }
    }, 0); // 1000
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * Format bytes
   * @param bytes (File size in bytes)
   */
  formatBytes(bytes: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
  }

  /**
   * Hidden Drag and Drop
   * Click in background
   */
  @HostListener('document:click', ['$event'])
  onMouseEnter(event: any) {
    if (event.target.className == 'content show') {
      // this.status = 'hidden';
    } else if (event.target.className.search('btn-show-dad') == 24 || event.target.className.search('btn-show-dad') == 21 || event.target.className == 'btn-show-dad') {
      this.status = 'show';
    }
  }

  /**
   * Show message
   */
  openSnackBar() {
    this._notifyService.showSuccess('Su planilla se importo con éxito', 'Bien');
  }
}
