import { Component, OnInit } from '@angular/core';
import { Resolve, Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AwsCognitoService } from '../../services/service.index';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {
  public routeParams = this.route.snapshot.paramMap;
  public code = String(this.routeParams.get('code'));

  constructor(
    private location: Location,
    private router: Router,
    public route: ActivatedRoute,
    private _awsCognitoService: AwsCognitoService
  ) {
    //
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.code = params.code;
    });
    if (this.code) {
      this._awsCognitoService.getTokenDetails(this.code).subscribe(
        (response) => {
          localStorage.setItem('token', response.id_token);
          localStorage.setItem('refresh_token', response.refresh_token);
          if (response) {
            this.router.navigate(['admin']);
          }
          return of(response);
        },
        (err) => {
          console.log(err);
          return err;
        }
      );
    }
  }
}
