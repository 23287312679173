import { Injectable } from '@angular/core';
import { CONFIG } from "../../config/config";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CostService {

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { }

  postCost(data:any,skipXubio:boolean,transaccionid:any) {
    const path = skipXubio ? '?skipXubio=true' : '';
    const headers = { 'Content-Type': 'text/plain' };
    const body = data;
    if(transaccionid){
      return this.http.put(`${CONFIG.API_PROD}cost/${transaccionid}${path}`, body, { headers })
    }else{
      return this.http.post(`${CONFIG.API_PROD}cost${path}`, body, { headers })
    }
  }

  getCost(data:any) {
    const path = `?vendorId=${data.vendorId}&docNumber=${data.docNumber}`;
    // const path = `?vendorId=2792513&docNumber=A-6364-04402116`;
    return this.http.get(`${CONFIG.API_PROD}cost${path}`)
  }
  
}