export const AWS = {
    // Dev & Local
    // client: '70o14hfkna52m4vcgo1gek1gre',
    // secret: '',

    // redirectURL: 'http://localhost/cashtroopers/callback.html',
    // cognitoTokenURL: 'https://dinocount-dev.auth.us-west-2.amazoncognito.com/oauth2/token',
    // logout: 'https://dinocount-dev.auth.us-west-2.amazoncognito.com/logout?client_id=70o14hfkna52m4vcgo1gek1gre&response_type=code&scope=openid+profile&redirect_uri=http://localhost/cashtroopers/callback.html'

    // redirectURL: 'https://dev.dinocount.dinocloudevelop.com/callback.html',
    // cognitoTokenURL: 'https://auth-dev.dinocount.dinocloudevelop.com/oauth2/token',
    // logout: 'https://auth-dev.dinocount.dinocloudevelop.com/logout?client_id=70o14hfkna52m4vcgo1gek1gre&response_type=code&scope=openid+profile&redirect_uri=https://dev.dinocount.dinocloudevelop.com/callback.html'

    // Prod
    client: '1kgbkvguiv0252n68rh9vmeahq',
    secret: '',

    redirectURL: 'https://dinocount.dinocloudevelop.com/callback.html',
    cognitoTokenURL: 'https://auth.dinocount.dinocloudevelop.com/oauth2/token',
    logout: 'https://auth.dinocount.dinocloudevelop.com/logout?client_id=1kgbkvguiv0252n68rh9vmeahq&response_type=code&scope=openid+profile&redirect_uri=https://dinocount.dinocloudevelop.com/callback.html'
  };
