import * as purchaseProducts from '../../store/states/purchase-products.state';
import { PurchaseProductsAction, PurchaseProductsActionType } from '../../store/actions/purchase-products.actions';

export function purchaseProductsReducer(state = purchaseProducts.initialStatePurchaseProducts, action: PurchaseProductsAction | any): purchaseProducts.StatePurchaseProducts {
  switch (action.type) {
    case PurchaseProductsActionType.Loading: {
      return { ...state, loading: true };
    }
    case PurchaseProductsActionType.LoadSuccess: {
      return purchaseProducts.purchaseProductsAdapter.setAll(action.payload.purchaseProducts, {
        ...state,
        error: false,
        loading: false
      });
    }
    case PurchaseProductsActionType.LoadFailure: {
      return purchaseProducts.purchaseProductsAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}
