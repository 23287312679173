import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Customers } from '../../models/customers/customers';
import { CustomerById } from '../../models/customers/customerById';

export interface StateCustomers extends EntityState<Customers> {
  error: boolean;
  loading: boolean;
  total: number;
}

/**
 * All Customers
 */

export const customersAdapter: EntityAdapter<Customers> = createEntityAdapter<Customers>({
  selectId: (customers: Customers) => customers.cliente_id
});

export const initialStateCustomers: StateCustomers = customersAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});

/**
 * Specific Customer by id
 */

export interface StateCustomerById {
  customer: any;
  error: boolean;
  loading: boolean;
}

export const customerByIdAdapter: EntityAdapter<CustomerById> = createEntityAdapter<CustomerById>({});

export const initialStateCustomerById: StateCustomerById = customerByIdAdapter.getInitialState({
  customer: {},
  error: false,
  loading: true,
});

