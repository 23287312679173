import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Salaries } from '../../models/salaries/salaries';

export interface StateSalaries {
  salaries: any;
  error: boolean;
  loading: boolean;
}

export const salariesAdapter: EntityAdapter<Salaries> = createEntityAdapter<Salaries>({});

export const initialStateSalaries: StateSalaries = salariesAdapter.getInitialState({
  salaries: {},
  error: false,
  loading: true,
});
