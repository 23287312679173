import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import * as XLSX from 'xlsx';

import { SalesPointsLoadAction } from 'src/app/store/actions/sales-points.actions';

import {
  SalariesLoadAction,
  SalariesLoadImportAction,
} from 'src/app/store/actions/salaries.actions';
import { SalariesParams } from 'src/app/models/salaries/salaries-params';

import { CustomersLoadAction } from 'src/app/store/actions/customers.actions';

import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

import { SalesProductsLoadAction } from 'src/app/store/actions/sales-products.actions';
import { SalesProductsParams } from 'src/app/models/sales-products/sales-products-params';

import * as salaries from 'src/app/store/selectors/salaries.selectors';
import * as accounts from 'src/app/store/selectors/accounts.selectors';
import * as salesPoints from 'src/app/store/selectors/sales-points.selectors';
import * as customers from 'src/app/store/selectors/customers.selectors';
import * as salesProducts from 'src/app/store/selectors/sales-products.selectors';

import * as moment from 'moment';
import * as arrays from 'src/hardcoded-values/forms/arrays';

// Utils
import Utils from 'src/app/utils/utils';

// Sweet alert
import swal from 'sweetalert2';

// Services
import {
  SharedService,
  InvoiceService,
  NotificationService,
  CustomersService,
} from 'src/app/services/service.index';

export interface PeriodicElement {
  client: string;
  accounts: [];
  totalAmount: string;
  totalVat: string;
  details: string;
  dueDate: string;
  issueDate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-bulk-load',
  templateUrl: './bulk-load.component.html',
  styleUrls: ['./bulk-load.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class BulkLoadComponent implements OnInit {
  @ViewChild('showDad') showDad: ElementRef<HTMLElement>;
  @ViewChild('boxScroll') boxScroll: ElementRef;

  displayedColumns: string[] = [
    'client',
    'currency',
    'exchangePrice',
    'totalAmount',
    'totalVat',
    'dueDate',
    'issueDate',
    'details',
  ];
  expandedDetail: string[] = [
    'account',
    'product',
    'productCount',
    'price',
    'vat',
    'totalAmount',
    'costs',
    'detail',
  ];
  dataToDisplay = [...ELEMENT_DATA];
  expandedElement: PeriodicElement | null;
  dataSource = new ExampleDataSource(this.dataToDisplay);
  compare: any = [];
  invoices: any = [];
  preInvoices: any = [];
  bodyInvoice: any = [];
  costsCenter: any = [];
  salesPoints: any = [];
  currency: any = arrays.currency;
  customers: any = [];
  itemProducts: any = [];
  successCount: number = 0;
  failCount: number = 0;
  totalAmount: number = 0;
  totalVat: number = 0;
  usdFlag: boolean = false;
  exchangePrice: number = 0;
  loadingUpload: boolean = false;

  // Loading
  loading?: boolean;
  error?: boolean;
  public error$?: Observable<boolean>;

  // Products
  loadingProducts?: boolean;
  errorProducts?: boolean;
  public errorProducts$?: Observable<boolean>;

  // Invoice
  loadingInvoice?: boolean;
  errorInvoice?: boolean;
  public errorInvoice$?: Observable<boolean>;

  // Accounts
  loadingAccounts?: boolean;
  errorAccounts?: boolean;
  public errorAccounts$?: Observable<boolean>;

  // Accounts
  loadingCustomers?: boolean;
  errorCustomers?: boolean;
  public errorCustomers$?: Observable<boolean>;

  // Accounts
  loadingSalesPoints?: boolean;
  errorSalesPoints?: boolean;
  public errorSalesPoints$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private datePipe: DatePipe,
    private _sharedService: SharedService,
    private _notifyService: NotificationService,
    private _invoiceService: InvoiceService,
    private _customersService: CustomersService
  ) {
    this._sharedService.changeTitile('Comprobantes de Venta - Carga Masiva');
    this._sharedService.changeStatusRowMenu(true);

    // Moment
    moment.locale('es');
  }

  ngOnInit(): void {
    /**
     * Load Data
     */
    this.store.dispatch(new SalariesLoadImportAction(null));
    this.store.dispatch(new SalariesLoadAction(<SalariesParams>{}));
    this.subscription.add(
      this.store
        .pipe(select(salaries.selectSalariesLoading))
        .subscribe((loading) => {
          if (loading) {
            this.compare = [];
            this.store
              .pipe(select(salaries.selectSalariesImport))
              .subscribe(
                (invoices) => (
                  (this.preInvoices = invoices ? invoices[0] : null),
                  this.preUploadFile()
                )
              );
          }
          this.loading = loading;
        })
    );
    this.store.pipe(select(salaries.selectSalariesError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError('Error al obtener datos', 'Invoices');
      }
      this.error = error;
    });

    /**
     * Get Sales Products
     */
    this.store.dispatch(new SalesProductsLoadAction(<SalesProductsParams>{}));
    this.subscription.add(
      this.store
        .pipe(select(salesProducts.selectSalesProductsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(salesProducts.selectAllSalesProducts))
              .subscribe(
                (salesProducts) => (this.itemProducts = salesProducts)
              );
          }
          this.loadingProducts = loading;
        })
    );
    this.store
      .pipe(select(salesProducts.selectSalesProductsError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError(
            'Error al obtener datos',
            'Productos de Venta'
          );
        }
        this.errorProducts = error;
      });

    /**
     * Get Costs Center
     */
    this.store.dispatch(
      new AccountsLoadAction(<AccountsParams>{ accountType: 'income' })
    );
    this.subscription.add(
      this.store
        .pipe(select(accounts.selectAccountsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(accounts.selectAllAccounts))
              .subscribe((accounts) => (this.costsCenter = accounts));
          }
          this.loadingAccounts = loading;
        })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError(
          'Error al obtener datos',
          'Centro de Costos'
        );
      }
      this.error = error;
    });

    /**
     * Get Sales Points
     */
    this.store.dispatch(new SalesPointsLoadAction(null));
    this.subscription.add(
      this.store
        .pipe(select(salesPoints.selectSalesPointsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(salesPoints.selectAllSalesPoints))
              .subscribe((data) => (this.salesPoints = data));
          }
          this.loadingSalesPoints = loading;
        })
    );
    this.store
      .pipe(select(salesPoints.selectSalesPointsError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError(
            'Error al obtener datos',
            'Puntos de Venta'
          );
        }
        this.error = error;
      });

    /**
     * Get Customers
     */
    this.store.dispatch(new CustomersLoadAction(null));
    this.subscription.add(
      this.store
        .pipe(select(customers.selectCustomersLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(customers.selectAllCustomers))
              .subscribe((data) => (this.customers = data));
          }
          this.loadingCustomers = loading;
        })
    );
    this.store
      .pipe(select(customers.selectCustomersError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError('Error al obtener datos', 'Clientes');
        }
        this.error = error;
      });
  }

  /**
   * Customer Selected
   */
  async getCustomerData(id: any) {
    let promises = await new Promise((resolve, reject) => {
      this._customersService
        .getCustomerById(id)
        .toPromise()
        .then(
          (customer: any) => {
            // Success
            let data = {
              nombre: customer.customers.nombre,
              codigo: Utils.replaceString(customer.customers.nombre),
              id: customer.customers.cliente_id,
              billingAddress: customer.customers.direccion,
              country: customer.customers.pais,
              province: customer.customers.provincia,
            };
            resolve(data);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promises;
  }

  /**
   * Pre Upload
   */
  preUploadFile() {
    this.totalAmount = 0;
    this.totalVat = 0;
    // Check data
    let error: boolean = false;
    let strError: string;
    let csvErrors: any = [];

    if (this.preInvoices && this.preInvoices.data) {
      this.preInvoices.data.map(async (element: any, index: number) => {
        // Clear errors
        strError = '';

        // Client
        if (!element['CLIENTE'] || element['CLIENTE'].length === 0) {
          strError += 'Cliente incompleto, ';
        } else {
          const searchCustomer = this.customers.find(
            (elementFind: any) => elementFind.nombre === element['CLIENTE']
          );
          if (!searchCustomer) {
            strError += 'Cliente incorrecto, ';
          }
        }

        // Cost Center
        if (!element['CENTRO COSTO'] || element['CENTRO COSTO'].length === 0) {
          strError += 'Centro de costos incompleto, ';
        } else {
          const searchAccount = this.costsCenter.find(
            (elementFind: any) =>
              elementFind.accountNumber === element['CENTRO COSTO']
          );

          if (!searchAccount) {
            strError += 'Centro de costos incorrecto, ';
          }
        }

        // Account
        if (!element['CUENTA'] || element['CUENTA'].length === 0) {
          strError += 'Cuenta incompleta, ';
        }

        // Amount
        if (!element['MONTO NETO'] || element['MONTO NETO'].length === 0) {
          strError += 'Monto Neto incompleto, ';
        } else {
          this.totalAmount = this.totalAmount + element['MONTO NETO'];
        }

        // Vat
        if (!element['IVA'] || element['IVA'].length === 0) {
          strError += 'Iva incompleto, ';
        } else {
          this.totalVat = this.totalVat + element['IVA'];
        }

        // Product
        if (!element['PRODUCTO'] || element['PRODUCTO'].length === 0) {
          strError += 'Producto incompleto, ';
        } else {
          const searchProduct = this.itemProducts.find(
            (elementFind: any) => elementFind.nombre === element['PRODUCTO']
          );
          if (!searchProduct) {
            strError += 'Producto incorrecto, ';
          }
        }

        // Detail
        if (!element['DETALLE '] || element['DETALLE '].length === 0) {
          strError += 'Detalle incompleto, ';
        }

        // Date
        if (
          !element['FECHA VENCIMIENTO'] ||
          element['FECHA VENCIMIENTO'].length === 0
        ) {
          strError += 'Fecha Vencimiento incompleto, ';
        }

        // Date of issue
        if (
          !element['FECHA EMISION'] ||
          element['FECHA EMISION'].length === 0
        ) {
          strError += 'Fecha emision incompleto, ';
        }

        // Currency
        if (!element['CURRENCY'] || element['CURRENCY'].length === 0) {
          strError += 'Currency incompleto, ';
        }

        // Xubio
        if (!element['XUBIO'] || element['XUBIO'].length === 0) {
          strError += 'Xubio incompleto, ';
        }

        // Invoice Id
        if (!element['INVOICEID'] || element['INVOICEID'].length === 0) {
          strError += 'Invioce Id incompleto, ';
        }

        // Transaction Type
        if (
          !element['TRANSACTION TYPE'] ||
          element['TRANSACTION TYPE'].length === 0 ||
          (element['TRANSACTION TYPE'] !== 1 &&
            element['TRANSACTION TYPE'] !== 2 &&
            element['TRANSACTION TYPE'] !== 3 &&
            element['TRANSACTION TYPE'] !== 6 &&
            element['TRANSACTION TYPE'] !== 8 &&
            element['TRANSACTION TYPE'] !== 10 &&
            element['TRANSACTION TYPE'] !== 99)
        ) {
          strError += 'Transaction Type incorrecto, ';
        }

        // Sales Point
        if (!element['SALES POINT'] || element['SALES POINT'].length === 0) {
          strError += 'Sales Point incompleto, ';
        } else {
          const searchSalesPoint = this.salesPoints.find(
            (elementFind: any) =>
              elementFind.puntoVenta.replace(/^0+/, '') ===
              element['SALES POINT'].toString()
          );

          if (!searchSalesPoint) {
            strError += 'Sales Point incorrecto, ';
          }
        }

        // Payment Condition
        if (
          !element['PAYMENT CONDITION '] ||
          element['PAYMENT CONDITION '].length === 0 ||
          (element['PAYMENT CONDITION '] !== 1 &&
            element['PAYMENT CONDITION '] !== 2)
        ) {
          strError += 'Payment Condition incorrecto, ';
        }

        // Exchange Price
        if (
          !element['EXCHANGE PRICE'] ||
          element['EXCHANGE PRICE'].length === 0
        ) {
          strError += 'Exchange Price incompleto, ';
        } else {
          this.usdFlag = true;
          this.exchangePrice = element['EXCHANGE PRICE'];
        }

        // Description
        if (!element['DESCRIPTION'] || element['DESCRIPTION'].length === 0) {
          strError += 'Description incompleto, ';
        }

        // Producto Count
        if (
          !element['PRODUCT COUNT'] ||
          element['PRODUCT COUNT'].length === 0
        ) {
          strError += 'Product Count incompleto, ';
        }

        // Push data
        if (strError.length > 0) {
          csvErrors.push({
            CLIENTE: element['CLIENTE'],
            ERROR: strError.slice(0, -2),
          });
        }

        // Flag
        if (strError.length > 0) {
          if (!error) {
            error = !error;
          }
        }
      });
    }
    if (error) {
      let cancelUpload: HTMLElement = document.getElementsByClassName(
        'btn-show-dad'
      )[0] as HTMLElement;
      setTimeout(() => {
        cancelUpload.click();
      }, 250);

      let title = new Date()
        .toLocaleString()
        .replace(/\//g, '')
        .replace(/:/g, '')
        .replace(' ', '_');

      const ws = XLSX.utils.json_to_sheet(csvErrors);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'output');
      XLSX.writeFile(wb, `Comprobantes_Ventas_${title}.xlsx`);

      this._notifyService.showError('El archivo contenia errores', 'Error');
      // this.reloadCurrentRoute();
    } else {
      this.invoices =
        this.preInvoices && this.preInvoices.newData
          ? this.preInvoices.newData
          : null;
      this.dataSource.setData(this.invoices);
    }
  }

  /**
   * Upload
   */
  uploadFile() {
    this.compare = [...this.invoices];

    // Data USD
    let dataUsd = '';
    if (this.usdFlag) {
      dataUsd = `
        <div class="row-data top">
          <div>
            Moneda: 
          </div>
          <div>
            Dólar Estadounidense
          </div>
        </div>
        <div class="row-data">
          <div>
            Cotización:
          </div>
          <div>
            ${this.exchangePrice}
          </div>
        </div>
      `;
    }

    // Modal
    swal
      .fire({
        title: 'Carga Masiva',
        html: `
        Se realizará la Carga Masiva de los Comprobantes cargados. Este proceso puede demorar un tiempo.<br>
        <div class="content-data">
          <div class="row-data">
            <div>
              Monto Neto:
            </div>
            <div>
              ${this.totalAmount}
            </div>
          </div>
          <div class="row-data">
            <div>
              Monto IVA:
            </div>
            <div>
              ${this.totalVat}
            </div>
          </div>
          ${dataUsd}
        </div>
        `,
        showCancelButton: true,
        width: 415,
        confirmButtonText: 'Comenzar Carga',
        cancelButtonText: 'Volver ',
      })
      .then(async (result) => {
        if (result.value) {
          this.loadingUpload = true;

          let customer: any = [];
          let invoiceItems: any;

          for (const [idx, element] of this.invoices.entries()) {
            ++this.successCount;
            invoiceItems = [];

            // Customer
            const searchCustomer = this.customers.find(
              (elementFind: any) => elementFind.nombre === element.client
            );
            if (searchCustomer) {
              customer = await this.getCustomerData(searchCustomer.cliente_id);
            }

            // Sales Point
            const searchSalesPoint = this.salesPoints.find(
              (elementFind: any) =>
                elementFind.puntoVenta.replace(/^0+/, '') ===
                element.salesPoint.toString()
            );

            // Invoice Items
            for (const item of element.accounts) {
              // Cost Center
              const costCenter = this.costsCenter.find(
                (elementFind: any) => elementFind.accountNumber === item.costs
              );

              // Product
              const searchProduct = this.itemProducts.find(
                (elementFind: any) => elementFind.nombre === item.product
              );

              // Body
              invoiceItems.push({
                product: searchProduct,
                costCenter: {
                  id: costCenter.xubioId,
                  nombre: costCenter.accountName,
                  codigo: costCenter.xubioCode,
                },
                amount: item.price,
                vat: item.vat,
                totalAmount: item.totalAmount,
                productCount: item.productCount,
                description: item.detail,
              });
            }

            // Prepare Body
            this.bodyInvoice.push({
              ISOCurrencyCode: element.currency,
              currency: this.currency.find(
                (find: any) => find.ISOCurrencyCode == element.currency
              ),
              exchangePrice: this.exchangePrice,
              customer: customer,
              date: element.issueDate,
              description: element.description,
              expiringDate: element.dueDate,
              invoiceItems: invoiceItems,
              name: element.client,
              originalAmount: element.totalAmount,
              paymentCondition: element.paymentCondition,
              province: customer.province,
              recordedAmount: 0,
              salesPoint: searchSalesPoint,
              taxAmount: element.totalVat,
              totalAmount: element.totalAmount + element.totalVat,
              transactionType: element.transactionType,
              xubioInvoiceNumber: element.invoiceid,
              skipXubio: element.xubio == 'SI' ? false : true,
            });

            // Clear Data
            delete this.compare[idx];
            this.dataSource.setData(
              this.compare.filter((compare: any) => compare)
            );
          }

          if (this.compare.length === this.successCount) {
            this.invoices = [];
            this.dataSource.setData([]);
            this.totalAmount = 0;
            this.totalVat = 0;
          }

          // Send Massive Invoice
          this._invoiceService.postMassiveInvoice(this.bodyInvoice).subscribe(
            (res) => {
              let data: any = res;
              if (data.status) {
                this.bodyInvoice = [];
                this._notifyService.showSuccess(
                  'Los datos se importaron con éxito!',
                  'Bien'
                );
              } else {
                this._notifyService.showError(
                  'Error al enviar datos',
                  'Invoice'
                );
              }
              this.processEnd();
            },
            (err) => {
              this._notifyService.showError('Error al enviar datos', 'Invoice');
              console.log(err);
              this.processEnd();
            }
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Cancel
        }
      });
  }

  /**
   * Cancel Upload
   */
  cancelUpload() {
    this.invoices = [];
    this.dataSource.setData([]);
  }

  /**
   * Add Data
   */
  addData() {
    const randomElementIndex = Math.floor(Math.random() * ELEMENT_DATA.length);
    this.dataToDisplay = [
      ...this.dataToDisplay,
      ELEMENT_DATA[randomElementIndex],
    ];
    this.dataSource.setData(this.dataToDisplay);
  }

  /**
   * Remove Data
   */
  removeData() {
    this.dataToDisplay = this.dataToDisplay.slice(0, -1);
    this.dataSource.setData(this.dataToDisplay);
  }

  /**
   * Process end
   */
  processEnd() {
    this.loadingUpload = false;
    let el: HTMLElement = document.getElementsByClassName(
      'btn'
    )[0] as HTMLElement;
    el.click();
    setTimeout(() => {
      // this.reloadCurrentRoute();
    }, 2000);
  }

  /**
   * Momentarily reload page but in the future
   * remplace for suscription
   */
  reloadCurrentRoute() {
    window.location.reload();
  }
}

class ExampleDataSource extends DataSource<PeriodicElement> {
  private _dataStream = new ReplaySubject<PeriodicElement[]>();

  constructor(initialData: PeriodicElement[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<PeriodicElement[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(data: PeriodicElement[]) {
    this._dataStream.next(data);
  }
}
