<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
        <span class="spacer">
      {{titleSection}}
    </span>
        <div class="circle-notification">
            3
        </div>
        <button mat-icon-button mat-button [matMenuTriggerFor]="notification">
      <mat-icon>notifications</mat-icon>
    </button>

        <!-- Show notifications -->
        <mat-menu #notification="matMenu" [overlapTrigger]="false">
            <button mat-menu-item style="white-space: normal">
        AFIP autorizo la emisión de la Factura N° 0001 - 0000022
      </button>
            <button mat-menu-item>
        Error en la emisión del Recibo N° 0001 - 0000321
      </button>
            <button mat-menu-item>
        AFIP autorizó la emision de la Nota de Crédito N° 0001 - 0000032
      </button>
        </mat-menu>

    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="statusRowMenu && !statusBackMenu; else backBtn" routerLink="/admin/">
        <button mat-icon-button>
      <mat-icon>home</mat-icon>
    </button>
        <span>Volver al inicio</span>
    </mat-toolbar-row>

    <ng-template #backBtn>
        <mat-toolbar-row *ngIf="statusBackMenu" (click)="back()">
            <button mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
            <span>Regresar</span>
        </mat-toolbar-row>
    </ng-template>
</mat-toolbar>