import * as salesPoints from '../../store/states/sales-points.state';
import { SalesPointsAction, SalesPointsActionType } from '../../store/actions/sales-points.actions';

export function salesPointsReducer(state = salesPoints.initialStateSalesPoints, action: SalesPointsAction | any): salesPoints.StateSalesPoints {
  switch (action.type) {
    case SalesPointsActionType.Loading: {
      return { ...state, loading: true };
    }
    case SalesPointsActionType.LoadSuccess: {
      return salesPoints.salesPointsAdapter.setAll(action.payload.salesPoints, {
        ...state,
        error: false,
        loading: false
      });
    }
    case SalesPointsActionType.LoadFailure: {
      return salesPoints.salesPointsAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}
