import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService, AwsCognitoService } from '../../services/service.index';
import { AWS } from '../../config/aws';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public _authService: AuthService,
    public _awsCognitoService: AwsCognitoService
  ) {
    //
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this._authService.getTokenStorage()) {
      request = this.addToken(request, this._authService.getTokenStorage());
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0)) {
          return this.handle401Error(request, next);
        } else { 
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (request.url != AWS.cognitoTokenURL) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return request;
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      const refreshToken = this._authService.getRefreshTokenStorage();
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this._awsCognitoService.refreshToken(refreshToken).pipe(
        take(1),
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.id_token);
          localStorage.setItem('token', token.id_token);
          return next.handle(this.addToken(request, token.id_token));
        }),
        catchError((error: HttpErrorResponse) => {
          let pipeMsj = error.error.message || error.error.mensaje || error.error.error;
          setTimeout(() => {
            Swal.fire({
              title: 'Error',
              text: 'La sesion ha expirado, vuelva a loguearse por favor.',
              icon: 'error',
              width: 300,
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(async (result) => {
              if (result.value) {
                this._authService.removeSessionStorage();
                window.location.href = AWS.logout;
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Cancel
              }
            });
          }, 500);
          return throwError(pipeMsj);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          localStorage.setItem('token', jwt);
          return next.handle(this.addToken(request, jwt));
        }),
        catchError((error: HttpErrorResponse) => {
          let pipeMsj = error.error.message || error.error.mensaje || error.error.error;
          setTimeout(() => {
            Swal.fire({
              title: 'Error',
              text: 'La sesion ha expirado, vuelva a loguearse por favor.',
              icon: 'error',
              width: 300,
              showConfirmButton: true,
              showCancelButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(async (result) => {
              if (result.value) {
                this._authService.removeSessionStorage();
                window.location.href = AWS.logout;
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Cancel
              }
            });
          }, 500);
          return throwError(pipeMsj);
        })
      );
    }
  }
}
