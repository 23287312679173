import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminComponent } from './pages/admin/admin.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { PagenotfoundComponent } from './shared/pagenotfound/pagenotfound.component';

//Services
import { LoginGuard , TokenResolverService } from "src/app/services/service.index";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [LoginGuard],
    // resolve: {
    //   access: TokenResolverService
    // }
  },
  {
    path: 'callback',
    // redirectTo: '/admin'
    component: CallbackComponent,
  },
  {
    path: '', redirectTo: '/admin', pathMatch: 'full'
  },
  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }