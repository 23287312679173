import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AWS } from "../../config/aws";
import { Observable } from 'rxjs';

//Services
import { AuthService } from "src/app/services/auth/auth.service";
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (this._authService.isLogin()) {
        return true
      } else {
        window.location.href = AWS.logout;
        return false
      }

  }
}
