import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { AccountsService } from 'src/app/services/service.index';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';
import { AccountsResponse } from 'src/app/models/accounts/accounts-response';
import * as accounts from '../actions/accounts.actions';

@Injectable()
export class AccountsEffects {
  constructor(
    private _accountsService: AccountsService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadAccounts$ = createEffect(() =>
    this.actions$.pipe(
        ofType<accounts.AccountsLoadAction>(accounts.AccountsActionType.Loading),
        map((action) => action.payload),
        switchMap((params: AccountsParams) =>
        this._accountsService.getAllAccounts(params.accountType).pipe(
          map((response : any) => new accounts.AccountsLoadSuccessAction(response)),
          catchError((error) => of(new accounts.AccountsLoadFailAction(error)))
        )
      )
    )
  );
}