import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { ProvidersService } from 'src/app/services/service.index';
import { ProvidersParams } from 'src/app/models/providers/providers-params';
import { ProvidersResponse } from 'src/app/models/providers/providers-response';
import { ProviderByIdParams } from 'src/app/models/providers/providerById-params';
import * as providers from '../actions/providers.actions';

@Injectable()
export class ProvidersEffects {
  constructor(
    private _providersService: ProvidersService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadProviders$ = createEffect(() =>
    this.actions$.pipe(
        ofType<providers.ProvidersLoadAction>(providers.ProvidersActionType.Loading),
        map((action) => action.payload),
        switchMap((params) =>
        this._providersService.getAllProviders().pipe(
          map((response : any) => new providers.ProvidersLoadSuccessAction(response)),
          catchError((error) => of(new providers.ProvidersLoadFailAction(error)))
        )
      )
    )
  );
  
  public loadProviderById$ = createEffect(() =>
    this.actions$.pipe(
        ofType<providers.ProvidersLoadByIdAction>(providers.ProvidersActionType.LoadingById),
        map((action) => action.payload),
        switchMap((params: ProviderByIdParams) =>
        this._providersService.getProviderById(params.proveedorid).pipe(
          map((response : any) => new providers.ProvidersLoadByIdASuccessAction(response)),
          catchError((error) => of(new providers.ProvidersLoadByIdFailAction(error)))
        )
      )
    )
  );
}