import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { ProvidersLoadAction } from 'src/app/store/actions/providers.actions';
import { ProvidersParams } from 'src/app/models/providers/providers-params';

import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

import { PurchaseProductsLoadAction } from 'src/app/store/actions/purchase-products.actions';
import { PurchaseProductsParams } from 'src/app/models/purchase-products/purchase-products-params';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  statusLeftBar:boolean = false;

  constructor(
    private _sharedService: SharedService,
    public store: Store<GlobalState>
  ) {
    this._sharedService.changeTitile('Comprobantes de Compra');
    this._sharedService.changeStatusRowMenu(true);
  }

  ngOnInit(): void {
    this.loadProviders();
    this.loadAccounts();
    this.loadPurchaseProducts();
  }

  /**
   * Change Open or Close left bar
   */
  changeStatusLeftBar(): void {
    this.statusLeftBar = !this.statusLeftBar;
    this._sharedService.changeStatusLeftBar(this.statusLeftBar)
  }

  /**
   * Providers
   */
  private loadProviders(): void {
    this.store.dispatch(new ProvidersLoadAction(<ProvidersParams>{}));
  }
  
  /**
   * Costs Center
   */
  private loadAccounts(): void {
    this.store.dispatch(new AccountsLoadAction(<AccountsParams>{accountType : 'outcome'}));
  }
  
  /**
   * Purchase Products
   */
   private loadPurchaseProducts(): void {
    this.store.dispatch(new PurchaseProductsLoadAction(<PurchaseProductsParams>{}));
  }
}
