import * as accounts from '../../store/states/accounts.state';
import { AccountsAction, AccountsActionType } from '../../store/actions/accounts.actions';

export function accountsReducer(state = accounts.initialStateAccounts, action: AccountsAction | any): accounts.StateAccounts {
  switch (action.type) {
    case AccountsActionType.Loading: {
      return { ...state, loading: true };
    }
    case AccountsActionType.LoadSuccess: {
      return accounts.accountsAdapter.setAll(action.payload.accounts, {
        ...state,
        error: false,
        loading: false
      });
    }
    case AccountsActionType.LoadFailure: {
      return accounts.accountsAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}
