import * as buys from '../../store/states/buys.state';
import { BuysAction, BuysActionType } from '../../store/actions/buys.actions';

export function buysReducer(state = buys.initialState, action: BuysAction | any): buys.State {
  switch (action.type) {
    case BuysActionType.Loading: {
      return { ...state, loading: true };
    }
    case BuysActionType.LoadSuccess: {
      return buys.buysAdapter.setAll(action.payload.buys, {
        ...state,
        error: false,
        loading: false,
        total: action.payload.total,
      });
    }
    case BuysActionType.LoadFailure: {
      return buys.buysAdapter.removeAll({
        ...state,
        error: true,
        loading: false,
        total: 0,
      });
    }
    default:
      return state;
  }
}
