<div class="text-welcome">
    Bienvenidx a:
</div>
<mat-card>
    <mat-card-content>
        <img src="assets/menu/{{item.icon}}">
        <span>
            {{item.title}}
        </span>
    </mat-card-content>
</mat-card>