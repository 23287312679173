export default class Utils {
  /**
   * Add business days
   */
  static addBusinessDays(days: number): Date {
    const d = new Date();
    const day = d.getDay();
    d.setDate(
      d.getDate() +
        days +
        (day === 6 ? 2 : +!day) +
        Math.floor((days - 1 + (day % 6 || 1)) / 5) * 2
    );
    return d;
  }

  /**
   * Replace string
   */
  static replaceString(data: string) {
    if (data) {
      let parce = data.trim().replace(/ - /g, '_');
      parce = parce.replace(/ /g, '_');
      parce = parce.replace(/&/g, 'Y');
      parce = parce.replace(/-/g, '_');
      parce = parce.replace(/__/g, '_');
      let str = parce.replace(/\./g, '');
      return str;
    } else {
      return false;
    }
  }
}
