import { Validators } from '@angular/forms';

export const costItems = {
  product: [ , Validators.required],
  costCenter: [ , Validators.required],
  amount: ['', Validators.required],
  vat: [0.21, Validators.required],
  notTaxedAmount : [0],
  totalAmount: [''],
  productCount: [1, Validators.required],
  description: ['', Validators.required],
};

export const perceptionItems = {
  amount: [''],
  taxPerception: [ , ''],
};