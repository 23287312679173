import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { PurchaseProductsService } from 'src/app/services/service.index';
import { PurchaseProductsParams } from 'src/app/models/purchase-products/purchase-products-params';
import { PurchaseProductsResponse } from 'src/app/models/purchase-products/purchase-products-response';
import * as purchaseProducts from '../actions/purchase-products.actions';

@Injectable()
export class PurchaseProductsEffects {
  constructor(
    private _purchaseProductsService: PurchaseProductsService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadPurchaseProducts$ = createEffect(() =>
    this.actions$.pipe(
        ofType<purchaseProducts.PurchaseProductsLoadAction>(purchaseProducts.PurchaseProductsActionType.Loading),
        map((action) => action.payload),
        switchMap((params) =>
        this._purchaseProductsService.getAllPurchaseProducts().pipe(
          map((response : any) => new purchaseProducts.PurchaseProductsLoadSuccessAction(response)),
          catchError((error) => of(new purchaseProducts.PurchaseProductsLoadFailAction(error)))
        )
      )
    )
  );
}