import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Sales } from '../../models/sales/sales';

export interface StateSales extends EntityState<Sales> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const salesAdapter: EntityAdapter<Sales> = createEntityAdapter<Sales>({
  selectId: (sales: Sales) => sales.position
});

export const initialStateSales: StateSales = salesAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
