<p class="title-page">
    Historial Planillas de Impuestos
</p>

<mat-grid-list cols="1" rowHeight="60">
    <mat-grid-tile>
        <app-date></app-date>
    </mat-grid-tile>
</mat-grid-list>

<mat-grid-list cols="1" rowHeight="60">
    <mat-grid-tile>
        <app-action icon="print" name="Imprimir" [status]="!selection.hasValue()"></app-action>
        <app-action icon="file_download" name="Descargar" [status]="!selection.hasValue()" [table]='table' [selection]='selection'></app-action>
        <app-action icon="delete" name="Eliminar" [status]="!selection.hasValue()" [selection]='selection'></app-action>
    </mat-grid-tile>
</mat-grid-list>

<!--
    <div class="table-header">
        <div class="dc-h3 header">
            Buys ({{buysTotal}})
        </div>
        <div class="search-bar">
            <input class="dc-search" placeholder="Search Customer" (keyup)="filterSubject.next($event.target.value)">
        </div>
    </div>
-->

<div class="mat-elevation-z8" #tableBuys>
    <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" [matSortActive]="defaultSort.active" [matSortDirection]="defaultSort.direction">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
        </ng-container>

        <!-- CUIL Column -->
        <ng-container matColumnDef="cuil">
            <th mat-header-cell *matHeaderCellDef>CUIL</th>
            <td mat-cell *matCellDef="let element">{{element.cuil}}</td>
        </ng-container>

        <!-- Fullname Column -->
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef>Apellido y Nombre</th>
            <td mat-cell *matCellDef="let element">{{element.fullname}}</td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Tipo de moneda</th>
            <td mat-cell *matCellDef="let element">{{element.type}}</td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>Costo Total</th>
            <td mat-cell *matCellDef="let element">{{element.price}}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha de pago</th>
            <td mat-cell *matCellDef="let element">{{element.date | date: 'dd/MM/yyyy'}}</td>
        </ng-container>

        <!-- Costs Column -->
        <ng-container matColumnDef="costs">
            <th mat-header-cell *matHeaderCellDef>Centro de Costos</th>
            <td mat-cell *matCellDef="let element">{{element.costs}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
    </table>

    <mat-paginator [length]="buysTotal" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"></mat-paginator>
</div>