<app-loading
    *ngIf="loadingUpload || loadingAccounts|| loadingInvoice || loadingCustomers || loadingSalesPoints || loadingProducts">
</app-loading>

<p #boxScroll class="title-page">
    Importación de Datos para Ventas
    <a mat-flat-button>
        <mat-icon class="btn-show-dad btn">add</mat-icon>
    </a>
</p>

<!-- 
    <div class="demo-button-container">
        <button mat-raised-button (click)="addData()" class="demo-button">
            Add data
        </button>
        <button mat-raised-button [disabled]="!dataToDisplay.length" (click)="removeData()" class="demo-button">
            Remove data
        </button>
    </div> 
-->

<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
    <!-- Client -->
    <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef class="w-165">Cliente</th>
        <td mat-cell *matCellDef="let element">{{element.client}}</td>
        <td mat-footer-cell *matFooterCellDef class="td-footer"> TOTAL </td>
    </ng-container>

    <!-- Currency -->
    <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef class="w-100">
            <span class="block"></span>
            Moneda
        </th>
        <td mat-cell *matCellDef="let element">{{element.currency}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Exchange Price -->
    <ng-container matColumnDef="exchangePrice">
        <th mat-header-cell *matHeaderCellDef class="w-100">Cotización</th>
        <td mat-cell *matCellDef="let element">{{element.exchangePrice}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Price -->
    <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef class="w-135">Monto Neto</th>
        <td mat-cell *matCellDef="let element">{{element.totalAmount | number}}</td>
        <td mat-footer-cell *matFooterCellDef>{{this.totalAmount | number}}</td>
    </ng-container>

    <!-- Vat -->
    <ng-container matColumnDef="totalVat">
        <th mat-header-cell *matHeaderCellDef class="w-135">Monto IVA</th>
        <td mat-cell *matCellDef="let element">{{element.totalVat | number}}</td>
        <td mat-footer-cell *matFooterCellDef>{{this.totalVat | number}}</td>
    </ng-container>

    <!-- Due Date -->
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef class="w-165">Fecha Vto.</th>
        <td mat-cell *matCellDef="let element">{{element.dueDate}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Issue Date -->
    <ng-container matColumnDef="issueDate">
        <th mat-header-cell *matHeaderCellDef class="w-165">Fecha Emisión</th>
        <td mat-cell *matCellDef="let element">{{element.issueDate}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Details -->
    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef class="w-250">Detalles</th>
        <td mat-cell *matCellDef="let element" class="detail">
            Ver detalles
            <mat-icon class="mat-icon-rtl-mirror">
                {{element == expandedElement ? 'expand_less' : 'expand_more'}}
            </mat-icon>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
            [ngClass]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <table mat-table [dataSource]="element.accounts" multiTemplateDataRows
                    class="mat-elevation-z8 detail-table">
                    <!-- Acount -->
                    <ng-container matColumnDef="account">
                        <th mat-header-cell *matHeaderCellDef class="w-250">Cuenta</th>
                        <td mat-cell *matCellDef="let element">{{element.account}}</td>
                    </ng-container>
                    <!-- Product -->
                    <ng-container matColumnDef="product">
                        <th mat-header-cell *matHeaderCellDef class="w-135">Producto</th>
                        <td mat-cell *matCellDef="let element">{{element.product}}</td>
                    </ng-container>
                    <!-- Product Count -->
                    <ng-container matColumnDef="productCount">
                        <th mat-header-cell *matHeaderCellDef class="w-100">Cantidad</th>
                        <td mat-cell *matCellDef="let element">{{element.productCount}}</td>
                    </ng-container>
                    <!-- Amount -->
                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef class="w-100">Monto Neto</th>
                        <td mat-cell *matCellDef="let element">{{element.price | number}}</td>
                    </ng-container>
                    <!-- Vat -->
                    <ng-container matColumnDef="vat">
                        <th mat-header-cell *matHeaderCellDef class="w-100">Monto Iva</th>
                        <td mat-cell *matCellDef="let element">{{element.vat | number}}</td>
                    </ng-container>
                    <!-- Amount -->
                    <ng-container matColumnDef="totalAmount">
                        <th mat-header-cell *matHeaderCellDef class="w-100">Monto Total</th>
                        <td mat-cell *matCellDef="let element">{{element.totalAmount | number}}</td>
                    </ng-container>
                    <!-- Centro de Costos -->
                    <ng-container matColumnDef="costs">
                        <th mat-header-cell *matHeaderCellDef class="w-135">Centro de Costos</th>
                        <td mat-cell *matCellDef="let element">{{element.costs}}</td>
                    </ng-container>
                    <!-- Detail -->
                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef class="w-480">Detalle</th>
                        <td mat-cell *matCellDef="let element">{{element.detail}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="expandedDetail"></tr>
                    <tr mat-row *matRowDef="let row; columns: expandedDetail"></tr>
                </table>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="expand-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>

<div class="content-btns" *ngIf="invoices ? invoices.length > 0 : false">
    <button (click)="uploadFile()" mat-raised-button class="blue" color="primary">
        Cargar Ventas
    </button>
    <button (click)="cancelUpload()" mat-raised-button class="red btn-show-dad">
        <span id="cancelUpload" class="btn-show-dad">Cancelar</span>
    </button>
</div>

<app-drag-drop type='invoices'></app-drag-drop>