import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';

import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { SalariesLoadAction, SalariesLoadImportAction } from 'src/app/store/actions/salaries.actions';
import { SalariesParams } from 'src/app/models/salaries/salaries-params';

import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

import * as salaries from 'src/app/store/selectors/salaries.selectors';
import * as accounts from 'src/app/store/selectors/accounts.selectors';

import * as moment from 'moment';

// Sweet alert
import swal from 'sweetalert2';

// Services
import { SharedService, CostService, NotificationService } from 'src/app/services/service.index';

export interface PeriodicElement {
  cuil: string;
  fullname: string;
  type: string;
  price: string;
  date: string;
  costs: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss'],
})

export class TaxesComponent implements OnInit {
  @ViewChild('showDad') showDad: ElementRef<HTMLElement>;
  @ViewChild('boxScroll') boxScroll: ElementRef;

  displayedColumns: string[] = [
    'date',
    'detail',
    'period',
    'type',
    'price',
    'costs',
  ];
  dataToDisplay = [...ELEMENT_DATA];
  dataSource = new ExampleDataSource(this.dataToDisplay);
  compare : any;
  taxes : any = [];
  bodyTaxes : any = [];
  costsCenter: any = [];
  failCount : number = 0;
  successCount : number = 0;

  // Taxes
  loading?: boolean;
  error?: boolean;
  public error$?: Observable<boolean>;

  // Cost
  loadingTaxes?: boolean;
  errorCost?: boolean;
  public errorCost$?: Observable<boolean>;

  // Accounts
  loadingAccounts?: boolean;
  errorAccounts?: boolean;
  public errorAccounts$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private _sharedService: SharedService,
    private _notifyService : NotificationService,
    private _costService : CostService,
  ) {
    this._sharedService.changeTitile('Carga de Impuestos');
    this._sharedService.changeStatusRowMenu(true);

    // Moment
    moment.locale('es');
  }

  ngOnInit(): void {
    /**
     * Load Data
     */
    this.store.dispatch(new SalariesLoadImportAction(null));
    this.store.dispatch(new SalariesLoadAction(<SalariesParams>{}));
    this.subscription.add(
      this.store.pipe(select(salaries.selectSalariesLoading)).subscribe((loading) => {
        if(loading){
          this.compare = [];
          this.store.pipe(select(salaries.selectSalariesImport)).subscribe((taxes) => (
            this.dataSource.setData(taxes),
            this.taxes = taxes
          ));
        }
        this.loading = loading;
      })
    );
    this.store.pipe(select(salaries.selectSalariesError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Taxes");
      }
      this.error = error;
    })

    /**
     * Get Costs Center
     */
     this.store.dispatch(new AccountsLoadAction(<AccountsParams>{accountType : 'outcome'}));
     this.subscription.add(
      this.store.pipe(select(accounts.selectAccountsLoading)).subscribe((loading) => {
        if(loading){
          this.store.pipe(select(accounts.selectAllAccounts)).subscribe((accounts) => (
            this.costsCenter = accounts
          ))
        }
        this.loadingAccounts = loading;
      })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Centro de Costos");
      }
      this.error = error;
    })     
  }

  /**
   * Upload
   */
   uploadFile() {
    swal.fire({
      title: 'Carga de sueldos',
      text: 'La siguiente planilla se cargará en la base de datos',
      showCancelButton: true,
      width: 300,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Volver ',
    })
    .then(async (result) => {
      if (result.value) {
        let timeBatch = 0;
        let countBatch = 0;
        this.compare = [...this.taxes]

        // Save
        await Promise.all(this.taxes.map(async (element: any, idx: number) => {
          setTimeout(() => {
            this.loadingTaxes = true;

            // Search Account
            let index;
            const searchAccount = this.costsCenter.find((elementFind : any) => elementFind.accountNumber == element.costs);
            if (searchAccount) {
              index = this.costsCenter.indexOf(searchAccount);
              
              // Prepare Body
              this.bodyTaxes = {
                vendor: {
                    nombre: 'ARGENTINIAN TAXES ORGANISMS',
                    id: 111111111,
                    billingAddress: '',
                  },
                date: element.date,
                xubioInvoiceNumber: `${moment(element.date).format('MMYY')}-${this.replaceString(element.detail.toUpperCase())}`,
                ISOCurrencyCode: element.type,
                originalAmount: element.price,
                costItems: [{
                  costCenter: {
                    nombre: this.costsCenter[index].accountName,
                    id : this.costsCenter[index].xubioId,
                  },
                  amount: element.price,
                  notTaxedAmount: 0, 
                  description: element.detail, 
                }]
              };

              // Send Cost
              this._costService.postCost(this.bodyTaxes, true, false).subscribe(
                (res) => {
                  let data: any = res;
                  if (data.costs.costRaw.length >= 1 && data.costs.costRds.length >= 1) {
                    delete this.compare[idx];
                    this.dataSource.setData(this.compare.filter((element:any) =>element));
                    ++this.successCount;

                    if(this.compare.length === this.successCount){
                      this.loadingTaxes = false;
                      this.compare = [];
                      this.taxes = [];
                      this.bodyTaxes = [];
                      this.dataSource.setData([]);
                      this._notifyService.showSuccess("La planilla se ha guardado con éxito!", "Bien");
                      let el: HTMLElement = document.getElementsByClassName('btn')[0] as HTMLElement;
                      el.click();
                    }else if(this.compare.length === (this.successCount + this.failCount)){
                      this.loadingTaxes = false;
                      this._notifyService.showError("Los siguientes registros no fueron cargados", "Error");
                    }
                  } else {
                    this.loadingTaxes = false;
                    this._notifyService.showError("Error al enviar datos", "Cost");
                  }
                },
                (err) => {
                  this.loadingTaxes = false;
                  this._notifyService.showError("Error al enviar datos", "Cost");
                  console.log(err);
                }
              );  
            } else {
              ++this.failCount;
              this.loadingTaxes = false;
              this._notifyService.showError("Error al enviar datos, centro de costo no encontrado", element.detail);
            }
          }, timeBatch * 1);

          // Batch
          if(countBatch != 10){
            ++countBatch;
          }else{
            countBatch = 0;
            timeBatch = timeBatch + 3000;
          }          
        }));
      } else if (result.dismiss === swal.DismissReason.cancel) {
        // Cancel
      }
    });
  }

  /**
   * Replace string
   * @param data 
   * @returns 
   */
   replaceString(data:string){
    if(data){
      let parce = data.replace(/ /g,'');
      let str = parce.replace(/\./g,'');
      return str
    } else {
      return false
    }
  }  

  /**
   * Cancel Upload
   */
  cancelUpload() {
    this.taxes = [];
    this.dataSource.setData([]);
  }

  /**
   * Add Data
   */
  addData() {
    const randomElementIndex = Math.floor(Math.random() * ELEMENT_DATA.length);
    this.dataToDisplay = [
      ...this.dataToDisplay,
      ELEMENT_DATA[randomElementIndex],
    ];
    this.dataSource.setData(this.dataToDisplay);
  }

  /**
   * Remove Data
   */
  removeData() {
    this.dataToDisplay = this.dataToDisplay.slice(0, -1);
    this.dataSource.setData(this.dataToDisplay);
  }
}

class ExampleDataSource extends DataSource<PeriodicElement> {
  private _dataStream = new ReplaySubject<PeriodicElement[]>();

  constructor(
    initialData: PeriodicElement[]
  ) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<PeriodicElement[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(data: PeriodicElement[]) {
    this._dataStream.next(data);
  }
}
