
import { Action } from '@ngrx/store'
import { SalariesParams } from '../../models/salaries/salaries-params';
import { SalariesResponse } from '../../models/salaries/salaries-response';

export enum SalariesActionType {
  Loading = '[Salaries] Loading',
  LoadSuccess = '[Salaries] LoadSuccess',
  LoadImport = '[Salaries] LoadImport',
  LoadFailure = '[Salaries] LoadFailure'
}

export class SalariesLoadAction implements Action {
  public readonly type = SalariesActionType.Loading;
  constructor(public payload: SalariesParams) {}
}

export class SalariesLoadSuccessAction implements Action {
  public readonly type = SalariesActionType.LoadSuccess;
  constructor(public payload: SalariesResponse) {}
}

export class SalariesLoadImportAction implements Action {
  public readonly type = SalariesActionType.LoadImport;
  constructor(public payload: SalariesResponse) {}
}

export class SalariesLoadFailAction implements Action {
  public readonly type = SalariesActionType.LoadFailure;
  constructor(public error: any) {}
}

export type SalariesAction = SalariesLoadAction | SalariesLoadSuccessAction | SalariesLoadImportAction | SalariesLoadFailAction;
