import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateCost, costAdapter } from '../../store/states/cost.state';

export const {
  selectIds: _selectCostDataIds,
  selectEntities: _selectCostEntities,
  selectAll: _selectAllCost,
  selectTotal: _selectCostTotal
} = costAdapter.getSelectors();

export const selectState = createFeatureSelector<StateCost>('cost');

export const selectCostIds = createSelector(
  selectState,
  _selectCostDataIds
);

export const selectCostEntities = createSelector(
  selectState,
  _selectCostEntities
);

export const selectAllCost = createSelector(
  selectState,
  _selectAllCost
);

export const selectCostError = createSelector(
  selectState,
  (state: StateCost): boolean => state.error
);

export const selectCostGetError = createSelector(
  selectState,
  (state: StateCost): boolean => state.errorGet
);

export const selectCostLoading = createSelector(
  selectState,
  (state: StateCost): boolean => state.loading
);

export const selectCostResponse = createSelector(
  selectState,
  (state: StateCost): any => state.costs
);

export const selectCostGetLoading = createSelector(
  selectState,
  (state: StateCost): boolean => state.loadingGetCost
);

export const selectGetCostResponse = createSelector(
  selectState,
  (state: StateCost): any => state.getCosts
);

export const selectCostTotal = createSelector(
  selectState,
  (state: StateCost): number => state.total
);
