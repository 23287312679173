import * as salaries from '../../store/states/salaries.state';
import {
  SalariesAction,
  SalariesActionType,
} from '../../store/actions/salaries.actions';

export function salariesReducer(
  stateSalaries = salaries.initialStateSalaries,
  action: SalariesAction | any
): salaries.StateSalaries {
  switch (action.type) {
    case SalariesActionType.Loading: {
      return { ...stateSalaries, loading: true };
    }
    case SalariesActionType.LoadSuccess: {
      return  { ...stateSalaries,
        salaries: action.payload.salaries,
        error: false,
        loading: false,
      };
    }
    case SalariesActionType.LoadImport: {
      return  { ...stateSalaries,
        salaries: action.payload,
        error: false,
        loading: false,
      };
    }
    case SalariesActionType.LoadFailure: {
      return  { ...stateSalaries,
        salaries: {},
        error: true,
        loading: false,
      };
    }
    default:
      return stateSalaries;
  }
}
