import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// To have router functions Available
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';

// Modules
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DateAdapter } from '@angular/material/core';
import { DateFormat } from './date-format/date-format';
import { NgxCurrencyModule } from "ngx-currency";

// Components
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { DetailComponent } from './receipt/detail/detail.component';
import { DetailSaleComponent } from './receipt/detail-sale/detail-sale.component';
import { ButtonSquareComponent } from './dashboard/button-square/button-square.component';
import { ButtonRectangleComponent } from './dashboard/button-rectangle/button-rectangle.component';
import { ProviderComponent } from './receipt/provider/provider.component';
import { SalesComponent } from './voucher-history/sales/sales.component';
import { BuysComponent } from './voucher-history/buys/buys.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidenavLeftComponent } from './sidenav-left/sidenav-left.component';
import { DragDropComponent } from './drag-drop/drag-drop.component';
import { ProgressComponent } from './progress/progress.component';
import { ActionComponent } from './action/action.component';
import { DateComponent } from './filter-by/date/date.component';
import { LoadingComponent } from './loading/loading.component';

// Directives
import { DragDropDirective } from '../directives/drag-drop/drag-drop.directive';

// Pipe
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    PagenotfoundComponent,
    ButtonSquareComponent,
    ButtonRectangleComponent,
    ProviderComponent,
    NavbarComponent,
    DetailComponent,
    DetailSaleComponent,
    SidenavLeftComponent,
    DragDropComponent,
    ProgressComponent,
    SalesComponent,
    BuysComponent,
    DateComponent,
    ActionComponent,
    DragDropDirective,
    LoadingComponent,
  ],
  exports: [
    PagenotfoundComponent,
    NavbarComponent,
    ButtonSquareComponent,
    ButtonRectangleComponent,
    ProviderComponent,
    NavbarComponent,
    DetailComponent,
    DetailSaleComponent,
    SidenavLeftComponent,
    DragDropComponent,
    ProgressComponent,
    SalesComponent,
    BuysComponent,
    DateComponent,
    ActionComponent,
    LoadingComponent,
  ],
  imports: [
    ToastrModule.forRoot(),
    RouterModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    MatTableModule,
    ReactiveFormsModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatTreeModule,
    MatSnackBarModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatTabsModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    NgxCurrencyModule 
  ],
  providers: [DatePipe, { provide: DateAdapter, useClass: DateFormat }],
})
export class SharedModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }
}
