import { Injectable } from '@angular/core';
import { CONFIG } from "../../config/config";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { }

  getAllProviders() {
    return this.http.get(`${CONFIG.API_PROD}providers`)
  }
  
  getProviderById(id:number) {
    return this.http.get(`${CONFIG.API_PROD}providers/${id}`)
  }  
}
