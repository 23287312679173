import * as cost from '../../store/states/cost.state';
import { CostAction, CostActionType } from '../../store/actions/cost.actions';

export function costReducer(state = cost.initialStateCost, action: CostAction | any): cost.StateCost {
  switch (action.type) {
    case CostActionType.Loading: {
      return { ...state, loading: true };
    }
    case CostActionType.LoadingGetCost: {
      return { ...state, loadingGetCost: true };
    }
    case CostActionType.LoadSuccess: {
      return  {
        ...state,
        error: false,
        costs: action.payload.costs,
        loading: false
      };
    }
    case CostActionType.LoadSuccessGet: {
      return  {
        ...state,
        errorGet: false,
        getCosts: action.payload.costs,
        loadingGetCost: false
      };
    }
    case CostActionType.LoadFailure: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }
    case CostActionType.LoadFailureGet: {
      return {
        ...state,
        errorGet: true,
        loadingGetCost: false,
      };
    }
    default:
      return state;
  }
}
