import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgReduxModule } from '@angular-redux/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routes
import { ADMIN_ROUTES } from './admin.routes';

// Modules
import { SharedModule } from "src/app/shared/shared.module";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule  } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxCurrencyModule } from "ngx-currency";

// Main Components
import { AdminComponent } from './admin.component';

// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ReceiptSaleComponent } from './receipt-sale/receipt-sale.component';
import { TaxesComponent } from './taxes/taxes.component';
import { SalariesComponent } from './salaries/salaries.component';
import { VoucherHistoryComponent } from './voucher-history/voucher-history.component';
import { EditVoucherComponent } from './edit-voucher/edit-voucher.component';
import { SalariesSheetloadComponent } from './salaries-sheetload/salaries-sheetload.component';
import { TaxesSheetloadComponent } from './taxes-sheetload/taxes-sheetload.component';
import { BulkLoadComponent } from './bulk-load/bulk-load.component';

@NgModule({
    declarations: [
        DashboardComponent,
        AdminComponent,
        ReceiptComponent,
        ReceiptSaleComponent,
        TaxesComponent,
        SalariesComponent,
        VoucherHistoryComponent,
        EditVoucherComponent,
        SalariesSheetloadComponent,
        TaxesSheetloadComponent,
        BulkLoadComponent
    ],
    exports: [
        MatPaginatorModule,
        MatSortModule,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ADMIN_ROUTES,
        ReactiveFormsModule,
        FormsModule,
        NgReduxModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatSidenavModule,
        MatButtonModule,
        MatGridListModule,
        MatCheckboxModule,
        MatSortModule,
        MatPaginatorModule,
        NgxCurrencyModule
    ],
    entryComponents: [
        // Component
    ],
})
export class AdminModule { }