<app-loading *ngIf="loadingAccounts|| loadingTaxes"></app-loading>

<p #boxScroll class="title-page">
    Importación de planilla de impuestos
    <a mat-flat-button>
        <mat-icon class="btn-show-dad btn">add</mat-icon>
    </a>
</p>

<!-- 
    <div class="demo-button-container">
        <button mat-raised-button (click)="addData()" class="demo-button">
            Add data
        </button>
        <button mat-raised-button [disabled]="!dataToDisplay.length" (click)="removeData()" class="demo-button">
            Remove data
        </button>
    </div> 
-->

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Date -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Fecha</th>
        <td mat-cell *matCellDef="let element">{{element.date}}</td>
    </ng-container>

    <!-- Detail -->
    <ng-container matColumnDef="detail">
        <th mat-header-cell *matHeaderCellDef>Detalle Impuesto</th>
        <td mat-cell *matCellDef="let element">{{element.detail}}</td>
    </ng-container>

    <!-- Period -->
    <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>Período</th>
        <td mat-cell *matCellDef="let element">{{element.period}}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo de moneda</th>
        <td mat-cell *matCellDef="let element">{{element.type}}</td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Costo Total</th>
        <td mat-cell *matCellDef="let element">{{element.price | number}}</td>
    </ng-container>

    <!-- Costs Column -->
    <ng-container matColumnDef="costs">
        <th mat-header-cell *matHeaderCellDef>Centro de Costos</th>
        <td mat-cell *matCellDef="let element">{{element.costs}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div class="content-btns" *ngIf="taxes ? taxes.length > 0 : false">
    <button (click)="uploadFile()" mat-raised-button class="blue" color="primary">
        Guardar
    </button>
    <button (click)="cancelUpload()" mat-raised-button class="red btn-show-dad">
        <span class="btn-show-dad">Cancelar</span>
    </button>
</div>

<app-drag-drop type='taxes'></app-drag-drop>