
import { Action } from '@ngrx/store'
import { SalesPointsParams } from '../../models/sales-points/sales-points-params';
import { SalesPointsResponse } from '../../models/sales-points/sales-points-response';

export enum SalesPointsActionType {
  Loading = '[SalesPoints] Loading',
  LoadSuccess = '[SalesPoints] LoadSuccess',
  LoadFailure = '[SalesPoints] LoadFailure'
}

export class SalesPointsLoadAction implements Action {
  public readonly type = SalesPointsActionType.Loading;
  constructor(public payload: SalesPointsParams) {}
}

export class SalesPointsLoadSuccessAction implements Action {
  public readonly type = SalesPointsActionType.LoadSuccess;
  constructor(public payload: SalesPointsResponse) {}
}

export class SalesPointsLoadFailAction implements Action {
  public readonly type = SalesPointsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type SalesPointsAction = SalesPointsLoadAction | SalesPointsLoadSuccessAction | SalesPointsLoadFailAction;