import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SalesProducts } from '../../models/sales-products/sales-products';

export interface StateSalesProducts extends EntityState<SalesProducts> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const salesProductsAdapter: EntityAdapter<SalesProducts> = createEntityAdapter<SalesProducts>({
  selectId: (salesProducts: SalesProducts) => salesProducts.productoid
});

export const initialStateSalesProducts: StateSalesProducts = salesProductsAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
