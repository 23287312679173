import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { reducers } from './store.index';

import { BuysEffects } from '../store/effects/buys.effects'
import { SalesEffects } from '../store/effects/sales.effects'
import { ProvidersEffects } from '../store/effects/providers.effects'
import { CustomersEffects } from '../store/effects/customers.effects'
import { AccountsEffects } from '../store/effects/accounts.effects'
import { AccountManagerEffects } from '../store/effects/account-manager.effects'
import { SalesProductsEffects } from '../store/effects/sales-products.effects'
import { PurchaseProductsEffects } from '../store/effects/purchase-products.effects'
import { SalesPointsEffects } from '../store/effects/sales-points.effects'
import { InvoiceEffects } from '../store/effects/invoice.effects'
import { CostEffects } from '../store/effects/cost.effects'
import { SalariesEffects } from '../store/effects/salaries.effects'

@NgModule({
  imports: [
    HttpClientModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(
      [ 
        BuysEffects, 
        SalesEffects,
        ProvidersEffects,
        CustomersEffects,
        AccountsEffects,
        AccountManagerEffects,
        SalesProductsEffects,
        PurchaseProductsEffects,
        SalesPointsEffects,
        InvoiceEffects,
        CostEffects,
        SalariesEffects
      ]
    ),
    StoreDevtoolsModule.instrument(
      { 
        maxAge: 25, 
        logOnly: environment.production 
      }
    ),
  ],
  exports: [
    StoreModule
  ]
})
export class StorageModule { }