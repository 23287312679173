
import { Action } from '@ngrx/store'
import { AccountsParams } from '../../models/accounts/accounts-params';
import { AccountsResponse } from '../../models/accounts/accounts-response';

export enum AccountsActionType {
  Loading = '[Accounts] Loading',
  LoadSuccess = '[Accounts] LoadSuccess',
  LoadFailure = '[Accounts] LoadFailure'
}

export class AccountsLoadAction implements Action {
  public readonly type = AccountsActionType.Loading;
  constructor(public payload: AccountsParams) {}
}

export class AccountsLoadSuccessAction implements Action {
  public readonly type = AccountsActionType.LoadSuccess;
  constructor(public payload: AccountsResponse) {}
}

export class AccountsLoadFailAction implements Action {
  public readonly type = AccountsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type AccountsAction = AccountsLoadAction | AccountsLoadSuccessAction | AccountsLoadFailAction;