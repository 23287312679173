import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State, buysAdapter } from '../../store/states/buys.state';

export const {
  selectIds: _selectBuysDataIds,
  selectEntities: _selectBuysEntities,
  selectAll: _selectAllBuys,
  selectTotal: _selectBuysTotal
} = buysAdapter.getSelectors();

export const selectState = createFeatureSelector<State>('buys');

export const selectBuysIds = createSelector(
  selectState,
  _selectBuysDataIds
);

export const selectBuysEntities = createSelector(
  selectState,
  _selectBuysEntities
);

export const selectAllBuys = createSelector(
  selectState,
  _selectAllBuys
);

export const selectBuysError = createSelector(
  selectState,
  (state: State): boolean => state.error
);

export const selectBuysLoading = createSelector(
  selectState,
  (state: State): boolean => state.loading
);

export const selectBuysTotal = createSelector(
  selectState,
  (state: State): number => state.total
);
