import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateSales, salesAdapter } from '../../store/states/sales.states';

export const {
  selectIds: _selectSalesDataIds,
  selectEntities: _selectSalesEntities,
  selectAll: _selectAllSales,
  selectTotal: _selectSalesTotal
} = salesAdapter.getSelectors();

export const selectState = createFeatureSelector<StateSales>('sales');

export const selectSalesIds = createSelector(
  selectState,
  _selectSalesDataIds
);

export const selectSalesEntities = createSelector(
  selectState,
  _selectSalesEntities
);

export const selectAllSales = createSelector(
  selectState,
  _selectAllSales
);

export const selectSalesError = createSelector(
  selectState,
  (state: StateSales): boolean => state.error
);

export const selectSalesLoading = createSelector(
  selectState,
  (state: StateSales): boolean => state.loading
);

export const selectSalesTotal = createSelector(
  selectState,
  (state: StateSales): number => state.total
);
