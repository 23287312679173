import * as providers from '../../store/states/providers.state';
import {
  ProvidersAction,
  ProvidersActionType,
} from '../../store/actions/providers.actions';

/**
 * All Providers
 */

export function providersReducer(
  state = providers.initialStateProviders,
  action: ProvidersAction | any
): providers.StateProviders {
  switch (action.type) {
    case ProvidersActionType.Loading: {
      return { ...state, loading: true };
    }
    case ProvidersActionType.LoadSuccess: {
      return providers.providersAdapter.setAll(action.payload.providers, {
        ...state,
        error: false,
        loading: false,
      });
    }
    case ProvidersActionType.LoadFailure: {
      return providers.providersAdapter.removeAll({
        ...state,
        error: true,
        loading: false,
      });
    }
    default:
      return state;
  }
}

/**
 * Specific Provider by id
 */

export function providerReducer(
  stateProvider = providers.initialStateProviderById,
  action: ProvidersAction | any
): providers.StateProviderById {
  switch (action.type) {
    case ProvidersActionType.LoadingById: {
      return { ...stateProvider, loading: true };
    }
    case ProvidersActionType.LoadSuccessById: {
      return {
        provider: action.payload.providers,
        error: false,
        loading: false,
      };
    }
    case ProvidersActionType.LoadFailureById: {
      return {
        provider: {},
        error: true,
        loading: false,
      };
    }
    default:
      return stateProvider;
  }
}
