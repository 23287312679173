
import { Action } from '@ngrx/store'
import { AccountManagerParams } from '../../models/account-manager/account-manager-params';
import { AccountManagerResponse } from '../../models/account-manager/account-manager-response';

export enum AccountManagerActionType {
  Loading = '[AccountManager] Loading',
  LoadSuccess = '[AccountManager] LoadSuccess',
  LoadFailure = '[AccountManager] LoadFailure'
}

export class AccountManagerLoadAction implements Action {
  public readonly type = AccountManagerActionType.Loading;
  constructor(public payload: AccountManagerParams) {}
}
 
export class AccountManagerLoadSuccessAction implements Action {
  public readonly type = AccountManagerActionType.LoadSuccess;
  constructor(public payload: AccountManagerResponse) {}
}

export class AccountManagerLoadFailAction implements Action {
  public readonly type = AccountManagerActionType.LoadFailure;
  constructor(public error: any) {}
}

export type AccountManagerAction = AccountManagerLoadAction | AccountManagerLoadSuccessAction | AccountManagerLoadFailAction;