
import { Action } from '@ngrx/store'
import { CostParams } from '../../models/cost/cost-params';
import { CostResponse } from '../../models/cost/cost-response';

export enum CostActionType {
  Loading = '[Cost] Loading',
  LoadSuccess = '[Cost] LoadSuccess',
  LoadFailure = '[Cost] LoadFailure',
  LoadingGetCost = '[Cost] Loading Get Cost',
  LoadSuccessGet = '[Cost] LoadSuccess Get Cost',
  LoadFailureGet = '[Cost] LoadFailure Get Cost',
}

export class CostLoadAction implements Action {
  public readonly type = CostActionType.Loading;
  constructor(public payload: CostParams) {}
}

export class CostLoadSuccessAction implements Action {
  public readonly type = CostActionType.LoadSuccess;
  constructor(public payload: CostResponse) {}
}

export class CostLoadFailAction implements Action {
  public readonly type = CostActionType.LoadFailure;
  constructor(public error: any) {}
}

export class CostGetAction implements Action {
  public readonly type = CostActionType.LoadingGetCost;
  constructor(public payload: CostParams) {}
}

export class CostLoadSuccessGetAction implements Action {
  public readonly type = CostActionType.LoadSuccessGet;
  constructor(public payload: CostResponse) {}
}

export class CostLoadGetFailAction implements Action {
  public readonly type = CostActionType.LoadFailureGet;
  constructor(public error: any) {}
}

export type CostAction = 
CostLoadAction | 
CostGetAction | 
CostLoadSuccessAction | 
CostLoadSuccessGetAction | 
CostLoadGetFailAction | 
CostLoadFailAction;