import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateInvoice, invoiceAdapter } from '../../store/states/invoice.state';

export const {
  selectIds: _selectInvoiceDataIds,
  selectEntities: _selectInvoiceEntities,
  selectAll: _selectAllInvoice,
  selectTotal: _selectInvoiceTotal
} = invoiceAdapter.getSelectors();

export const selectState = createFeatureSelector<StateInvoice>('invoice');

export const selectInvoiceIds = createSelector(
  selectState,
  _selectInvoiceDataIds
);

export const selectInvoiceEntities = createSelector(
  selectState,
  _selectInvoiceEntities
);

export const selectAllInvoice = createSelector(
  selectState,
  _selectAllInvoice
);

export const selectInvoiceError = createSelector(
  selectState,
  (state: StateInvoice): boolean => state.error
);

export const selectInvoiceGetError = createSelector(
  selectState,
  (state: StateInvoice): boolean => state.errorGet
);

export const selectInvoiceLoading = createSelector(
  selectState,
  (state: StateInvoice): boolean => state.loading
);

export const selectInvoiceResponse = createSelector(
  selectState,
  (state: StateInvoice): any => state.invoices
);

export const selectInvoiceGetLoading = createSelector(
  selectState,
  (state: StateInvoice): boolean => state.loadingGetInvoice
);

export const selectGetInvoiceResponse = createSelector(
  selectState,
  (state: StateInvoice): any => state.getInvoices
);

export const selectInvoiceTotal = createSelector(
  selectState,
  (state: StateInvoice): number => state.total
);
