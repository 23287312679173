
import { Action } from '@ngrx/store'
import { InvoiceParams } from '../../models/invoice/invoice-params';
import { InvoiceResponse } from '../../models/invoice/invoice-response';

export enum InvoiceActionType {
  Loading = '[Invoice] Loading',
  LoadSuccess = '[Invoice] LoadSuccess',
  LoadFailure = '[Invoice] LoadFailure',
  LoadingGetInvoice = '[Invoice] Loading Get Invoice',
  LoadSuccessGet = '[Invoice] LoadSuccess Get Invoice',
  LoadFailureGet = '[Invoice] LoadFailure Get Invoice',
}

export class InvoiceLoadAction implements Action {
  public readonly type = InvoiceActionType.Loading;
  constructor(public payload: InvoiceParams) {}
}

export class InvoiceLoadSuccessAction implements Action {
  public readonly type = InvoiceActionType.LoadSuccess;
  constructor(public payload: InvoiceResponse) {}
}

export class InvoiceLoadFailAction implements Action {
  public readonly type = InvoiceActionType.LoadFailure;
  constructor(public error: any) {}
}

export class InvoiceGetAction implements Action {
  public readonly type = InvoiceActionType.LoadingGetInvoice;
  constructor(public payload: InvoiceParams) {}
}

export class InvoiceLoadSuccessGetAction implements Action {
  public readonly type = InvoiceActionType.LoadSuccessGet;
  constructor(public payload: InvoiceResponse) {}
}

export class InvoiceLoadGetFailAction implements Action {
  public readonly type = InvoiceActionType.LoadFailureGet;
  constructor(public error: any) {}
}

export type InvoiceAction = 
InvoiceLoadAction | 
InvoiceGetAction | 
InvoiceLoadSuccessAction | 
InvoiceLoadSuccessGetAction | 
InvoiceLoadGetFailAction | 
InvoiceLoadFailAction;