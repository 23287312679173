import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as sales from '../actions/sales.actions';
import { SalesService } from 'src/app/services/service.index';
import { SalesParams } from 'src/app/models/sales/sales-params';
import { SalesResponse } from 'src/app/models/sales/sales-response';

@Injectable()
export class SalesEffects {
  constructor(
    private _salesService: SalesService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType<sales.SalesLoadAction>(sales.SalesActionType.Loading),
      map((action) => action.payload),
      switchMap((params: SalesParams) =>
        this._salesService.getSales(params).pipe(
          map((response: SalesResponse) => new sales.SalesLoadSuccessAction(response)),
          catchError((error) => of(new sales.SalesLoadFailAction(error)))
        )
      )
    )
  );
}
