<mat-card>
    <app-loading *ngIf="loading || loadingAccounts || loadingProducts || loadingInvoice || loadingCustomer">
    </app-loading>

    <div class="content-scroll">
        <form [formGroup]="detailForm">
            <mat-card-content>
                <div class="column-detail">
                    <p>
                        <ng-select placeholder="Tipo de Comprobante" formControlName="transactionType" appendTo="body">
                            <ng-option *ngFor="let item of transactionType" [value]="item.id">
                                {{item.nombre}}
                            </ng-option>
                        </ng-select>
                    </p>
                    <p>
                        <ng-select placeholder="Punto de Venta" formControlName="salesPoint" appendTo="body">
                            <ng-option *ngFor="let item of salesPointsSelect" [value]="item.puntoVentaId">
                                {{item.nombre}}
                            </ng-option>
                        </ng-select>
                    </p>
                    <p>
                        <ng-select placeholder="Cliente *" [disabled]="error || false" formControlName="customer" (change)="customerSelected($event, false)" appendTo="body">
                            <ng-option *ngFor="let item of itemSelect" [value]="item.cliente_id">
                                {{item.nombre}}
                            </ng-option>
                        </ng-select>
                    </p>
                    <p>
                        <mat-form-field class="example-full-width" appearance="legacy">
                            <mat-label>Vencimiento del pago *</mat-label>
                            <input formControlName="expiringDate" matInput [matDatepicker]="picker3">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field appearance="legacy">
                            <mat-label>Cotización</mat-label>
                            <input formControlName="exchangePrice" matInput placeholder="0.00" currencyMask [options]="{align: 'left',prefix: '$', thousands: '.', decimal: ','}">
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-checkbox class="example-margin" (change)="jumpXubio($event)" [checked]="jumpXubioState" [disabled]="jumpXubioDisabled">
                            Saltar Xubio
                        </mat-checkbox>
                    </p>
                </div>
                <div class="column-detail">
                    <p>
                        <mat-form-field appearance="legacy">
                            <mat-label>N° del comprobante *</mat-label>
                            <input formControlName="xubioInvoiceNumber" matInput placeholder="X - 000 - 000000">
                        </mat-form-field>
                    </p>
                    <p>
                        <ng-select placeholder="Condición de pago *" formControlName="paymentCondition" appendTo="body">
                            <ng-option *ngFor="let item of paymentCondition" [value]="item.id">
                                {{item.nombre}}
                            </ng-option>
                        </ng-select>
                    </p>
                    <p>
                        <mat-form-field class="example-full-width" appearance="legacy">
                            <mat-label>Fecha del comprobante *</mat-label>
                            <input formControlName="date" matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </p>
                    <p>
                        <ng-select placeholder="Moneda *" formControlName="currency" (change)="currencySelected($event, false)" appendTo="body">
                            <ng-option *ngFor="let item of currency" [value]="item.id">
                                {{item.nombre}}
                            </ng-option>
                        </ng-select>
                    </p>
                    <p>
                        <ng-select placeholder="Account Manager *" formControlName="accountManager" (change)="collaboratorsSelected($event)" appendTo="body">
                            <ng-option *ngFor="let item of collaborators" [value]="item.id">
                                {{item.fullName}}
                            </ng-option>
                        </ng-select>
                    </p>
                </div>
            </mat-card-content>

            <mat-card-content>
                <div class="table-data">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Producto/Servicio</th>
                                <th>Centro de costo</th>
                                <th>Detalle</th>
                                <th>Cantidad</th>
                                <th>Horas</th>
                                <th>Importe</th>
                                <th>Tasa IVA</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody formArrayName="invoiceItems" class="form-items">
                            <tr *ngFor="let itemForm of getInvoiceItemsForm.controls; let pointIndex=index" [formGroupName]="pointIndex">
                                <td>
                                    <a class="red-link" *ngIf="getInvoiceItemsForm.controls.length > 1; else emptyDelete" (click)="deleteFieldValue(pointIndex)">
                                        <mat-icon>delete</mat-icon>
                                    </a>
                                    <ng-template #emptyDelete>
                                        <span class="red-link empty">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-select placeholder="Producto/Servicio" formControlName="product" [disabled]="errorProducts || false" appendTo="body">
                                        <ng-option *ngFor="let item of itemProducts" [value]="item.productoid">
                                            {{item.nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <ng-select placeholder="Centro de costo" formControlName="costCenter" [disabled]="errorAccounts || false" appendTo="body">
                                        <ng-option *ngFor="let item of costsCenterSelect" [value]="item.id">
                                            {{item.nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Detalle del producto...</mat-label>
                                        <input type="text" formControlName="description" matInput placeholder="Detalle del producto...">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Cantidad</mat-label>
                                        <input (keyup)="calculateImport(pointIndex)" type="number" formControlName="productCount" matInput placeholder="0.00">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Horas</mat-label>
                                        <input formControlName="qty" matInput placeholder="00:00" currencyMask [options]="{align: 'left',prefix: '', thousands: '', decimal: ':', suffix: 'Hs'}">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Importe</mat-label>
                                        <input (keyup)="calculateImport(pointIndex)" formControlName="amount" matInput placeholder="0.00" currencyMask [options]="{align: 'left',prefix: '$', thousands: '.', decimal: ','}">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <ng-select placeholder="IVA" formControlName="vat" appendTo="body" (change)="calculateImport(pointIndex)">
                                        <ng-option *ngFor="let item of ivaSelect" [value]="item.value">
                                            {{item.nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Total</mat-label>
                                        <input formControlName="totalAmount" matInput placeholder="0.00" currencyMask [options]="{align: 'left',prefix: '$', thousands: '.', decimal: ','}">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                        <p>
                            <a mat-flat-button (click)="addFieldValue()">
                                <mat-icon>add</mat-icon>
                                Agregar item
                            </a>
                        </p>
                    </table>
                </div>
            </mat-card-content>

            <mat-card-content>
                <div class="table-data">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Percepción/Impuesto</th>
                                <th>Importe</th>
                            </tr>
                        </thead>
                        <tbody class="form-items">
                            <tr *ngFor="let field of perceptionArray; let i = index">
                                <td>
                                    <a class="red-link" *ngIf="perceptionArray.length > 1; else emptyDelete" (click)="deletePerceptionValue(i)">
                                        <mat-icon>delete</mat-icon>
                                    </a>
                                    <ng-template #emptyDelete>
                                        <span class="red-link empty">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </ng-template>
                                </td>
                                <td>
                                    <ng-select placeholder="Producto/Servicio" appendTo="body">
                                        <ng-option *ngFor="let item of arrayDemo" [value]="item.nombre">
                                            {{item.nombre}}
                                        </ng-option>
                                    </ng-select>
                                </td>
                                <td>
                                    <mat-form-field appearance="legacy">
                                        <mat-label>Importe</mat-label>
                                        <input matInput placeholder="0.00" currencyMask [options]="{align: 'left',prefix: '$', thousands: '.', decimal: ','}">
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                        <p>
                            <a mat-flat-button (click)="addPerceptionValue()">
                                <mat-icon>add</mat-icon>
                                Agregar item
                            </a>
                        </p>
                    </table>
                </div>
            </mat-card-content>
        </form>
    </div>

    <mat-card-actions>
        <mat-grid-list cols="6" rowHeight="20">
            <mat-grid-tile>
                Total bruto:
            </mat-grid-tile>
            <mat-grid-tile>
                {{totalGrossFooter}}
            </mat-grid-tile>
            <mat-grid-tile>
                Total impuestos:
            </mat-grid-tile>
            <mat-grid-tile>
                {{totalTaxFooter}}
            </mat-grid-tile>
            <mat-grid-tile>
                Total:
            </mat-grid-tile>
            <mat-grid-tile>
                {{totalFooter}}
            </mat-grid-tile>
        </mat-grid-list>
        <div class="content-btns">
            <button mat-raised-button [disabled]="!detailForm.valid" (click)="sendFormDetail()" class="blue" color="primary">
                Guardar
            </button>
            <button mat-raised-button (click)="resetForm()" class="red">
                Cancelar
            </button>
        </div>
    </mat-card-actions>
</mat-card>