import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateAccountManager, accountManagerAdapter } from '../../store/states/account-manager.state';

export const {
  selectIds: _selectAccountManagerDataIds,
  selectEntities: _selectAccountManagerEntities,
  selectAll: _selectAllAccountManager,
  selectTotal: _selectAccountManagerTotal
} = accountManagerAdapter.getSelectors();

export const selectState = createFeatureSelector<StateAccountManager>('accountManager');

export const selectAccountManagerIds = createSelector(
  selectState,
  _selectAccountManagerDataIds
);

export const selectAccountManagerEntities = createSelector(
  selectState,
  _selectAccountManagerEntities
);

export const selectAllAccountManager = createSelector(
  selectState,
  _selectAllAccountManager
);

export const selectAccountManagerError = createSelector(
  selectState,
  (state: StateAccountManager): boolean => state.error
);

export const selectAccountManagerLoading = createSelector(
  selectState,
  (state: StateAccountManager): boolean => state.loading
);

export const selectAccountManagerTotal = createSelector(
  selectState,
  (state: StateAccountManager): number => state.total
);
