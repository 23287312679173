import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StatePurchaseProducts, purchaseProductsAdapter } from '../../store/states/purchase-products.state';

export const {
  selectIds: _selectPurchaseProductsDataIds,
  selectEntities: _selectPurchaseProductsEntities,
  selectAll: _selectAllPurchaseProducts,
  selectTotal: _selectPurchaseProductsTotal
} = purchaseProductsAdapter.getSelectors();

export const selectState = createFeatureSelector<StatePurchaseProducts>('purchaseProducts');

export const selectPurchaseProductsIds = createSelector(
  selectState,
  _selectPurchaseProductsDataIds
);

export const selectPurchaseProductsEntities = createSelector(
  selectState,
  _selectPurchaseProductsEntities
);

export const selectAllPurchaseProducts = createSelector(
  selectState,
  _selectAllPurchaseProducts
);

export const selectPurchaseProductsError = createSelector(
  selectState,
  (state: StatePurchaseProducts): boolean => state.error
);

export const selectPurchaseProductsLoading = createSelector(
  selectState,
  (state: StatePurchaseProducts): boolean => state.loading
);

export const selectPurchaseProductsTotal = createSelector(
  selectState,
  (state: StatePurchaseProducts): number => state.total
);
