import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Buys } from '../../models/buys/buys';
import { BuysParams } from '../../models/buys/buys-params';
import { BuysResponse } from '../../models/buys/buys-response';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class BuysService {
  constructor(
    private httpClient: HttpClient
  ) {
    //
  }

  public getBuys(params: BuysParams): Observable<BuysResponse> {
    // return this.httpClient.post<Buys[]>("localhost:4200/buys", params);
    // return of(this.getFakeBuys(params)).pipe(delay(3000));
    return of(this.getFakeBuys(params)).pipe();
  }

  private getFakeBuys(params: BuysParams): BuysResponse {
    let data = <Buys[]>[];

    if (params.fromDate && params.toDate) {
      data = buys.filter(
        (e: any) => e.date >= params.fromDate && e.date <= params.toDate
      );
    } else {
      data = buys.filter(
        (b) =>
          ~b.fullname.toLocaleLowerCase().indexOf(params.filter) ||
          ~b.cuil.toLocaleLowerCase().indexOf(params.filter) ||
          ~b.costs.toLocaleLowerCase().indexOf(params.filter)
      );
    }
    
    // data.sort((a, b) => (a[params.sortField] > b[params.sortField] ? 1 : -1) * (params.sortDirection === "asc" ? 1 : -1));

    return {
      total: data.length,
      buys: data.slice(
        params.pageIndex * params.pageSize,
        (params.pageIndex + 1) * params.pageSize
      ),
    };
  }
}

const buys = <Buys[]>[
  <Buys>{
    position: 1,
    cuil: '20361244061',
    fullname: 'LUCAS ANDRES BORSATTO',
    type: 'AR$',
    price: '165.48',
    date: new Date(2021, 8, 20),
    costs: 'Centro de Costos Modelo 1',
  },
  <Buys>{
    position: 2,
    cuil: '20373161420',
    fullname: 'NICOLAS ESTEBAN SANCHEZ',
    type: 'AR$',
    price: '266.61',
    date: new Date(2021, 7, 8),
    costs: 'Centro de Costos Modelo 2',
  },
  <Buys>{
    position: 3,
    cuil: '20373209407',
    fullname: 'ZULIAN FRANCO DAVID',
    type: 'AR$',
    price: '567.74',
    date: new Date(2021, 7, 8),
    costs: 'Centro de Costos Modelo 2',
  },
  <Buys>{
    position: 4,
    cuil: '20376183417',
    fullname: 'FRANCO ARIEL SALONIA',
    type: 'AR$',
    price: '468.87',
    date: new Date(2021, 7, 3),
    costs: 'Centro de Costos Modelo 1',
  },
  <Buys>{
    position: 5,
    cuil: '20373161420',
    fullname: 'NICOLAS ESTEBAN SANCHEZ',
    type: 'AR$',
    price: '266.61',
    date: new Date(2021, 7, 5),
    costs: 'Centro de Costos Modelo 2',
  },
  <Buys>{
    position: 6,
    cuil: '20373209407',
    fullname: 'ZULIAN FRANCO DAVID',
    type: 'AR$',
    price: '567.74',
    date: new Date(2021, 7, 11),
    costs: 'Centro de Costos Modelo 2',
  },
  <Buys>{
    position: 7,
    cuil: '20376183417',
    fullname: 'FRANCO ARIEL SALONIA',
    type: 'AR$',
    price: '468.87',
    date: new Date(2021, 7, 10),
    costs: 'Centro de Costos Modelo 1',
  },
];
