import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { InvoiceLoadAction } from 'src/app/store/actions/invoice.actions';
import { InvoiceParams } from 'src/app/models/invoice/invoice-params';

import { CustomersLoadByIdAction } from 'src/app/store/actions/customers.actions';
import { CustomerByIdParams } from 'src/app/models/customers/customerById-params';

import { AccountManagerLoadAction } from 'src/app/store/actions/account-manager.actions';
import { AccountManagerParams } from 'src/app/models/account-manager/account-manager-params';

import * as arrays from 'src/hardcoded-values/forms/arrays';
import * as types from './types';
import * as customers from 'src/app/store/selectors/customers.selectors';
import * as accounts from 'src/app/store/selectors/accounts.selectors';
import * as accountManager from 'src/app/store/selectors/account-manager.selectors';
import * as salesProducts from 'src/app/store/selectors/sales-products.selectors';
import * as invoice from 'src/app/store/selectors/invoice.selectors';
import * as salesPoints from 'src/app/store/selectors/sales-points.selectors';
import * as moment from 'moment';

import { DatePipe } from '@angular/common';

// Utils
import Utils from 'src/app/utils/utils';

// Sweet alert
import swal from 'sweetalert2';

// Services
import {
  SharedService,
  NotificationService,
} from 'src/app/services/service.index';

@Component({
  selector: 'app-detail-sale',
  templateUrl: './detail-sale.component.html',
  styleUrls: ['./detail-sale.component.scss'],
})
export class DetailSaleComponent implements OnInit {
  detailForm: FormGroup;
  dataArray: any = [];

  transaccionid: number = null;
  total: number = 0;
  totalTax: number = 0;
  totalGross: number = 0;
  totalFooter: string;
  totalTaxFooter: string;
  totalGrossFooter: string;

  // Selects and Arrays
  itemSelect: any = [];
  itemProducts: any = [];
  paymentCondition: any = arrays.paymentCondition;
  currency: any = arrays.currency;
  currencySelect: any;
  customerSelect: any = [];
  collaborators: any = [];
  collaboratorsSelect: any = [];
  ivaSelect: any = arrays.ivaSelect;
  costsCenter: any = [];
  costsCenterSelect: any = [];
  salesPointsSelect: any = [];
  transactionType: any = arrays.transactionTypeSales;
  jumpXubioState: boolean = false;
  jumpXubioDisabled: boolean = false;

  // Arrays and Form demo
  arrayDemo: any = arrays.arrayDemo;
  fieldArray: Array<any> = [];
  perceptionArray: Array<any> = [];
  newAttribute: any = {};

  // Customers
  loading?: boolean;
  error?: boolean;
  public error$?: Observable<boolean>;

  // Accounts
  loadingAccounts?: boolean;
  errorAccounts?: boolean;
  public errorAccounts$?: Observable<boolean>;

  // Products
  loadingProducts?: boolean;
  errorProducts?: boolean;
  public errorProducts$?: Observable<boolean>;

  // Invoice
  loadingInvoice?: boolean;
  errorInvoice?: boolean;
  public errorInvoice$?: Observable<boolean>;

  // Customer
  loadingCustomer?: boolean;
  errorCustomer?: boolean;
  public errorCustomer$?: Observable<boolean>;

  // Sales Points
  loadingSalesPoints?: boolean;
  errorSalesPoints?: boolean;
  public errorSalesPoints$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _sharedService: SharedService,
    private _notifyService: NotificationService
  ) {
    /**
     * Generate form datail
     */
    this.detailForm = this.formBuilder.group({
      customer: [[], Validators.required],
      transactionType: [[], Validators.required],
      name: ['Cliente'], // Falta
      date: [new Date(), Validators.required],
      expiringDate: [Utils.addBusinessDays(10), Validators.required],
      salesPoint: [[], Validators.required],
      xubioInvoiceNumber: ['', Validators.required],
      paymentCondition: [[], Validators.required],
      accountManager: [[], Validators.required],
      exchangePrice: ['', Validators.required],
      ISOCurrencyCode: [''],
      currency: [[], Validators.required],
      originalAmount: [],
      totalAmount: [],
      taxAmount: [],
      recordedAmount: [0],
      province: [''],
      description: ['Factura Cliente'], // Falta
      invoiceItems: this.formBuilder.array([
        this.formBuilder.group(types.invoiceItems),
      ]),
    });

    /**
     * Start
     */
    this.updateStats();

    /**
     * Get detail order
     */
    this._sharedService.getDetailOrder$().subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.resetForm();

        this.dataArray = data;

        this.customerSelected(false, this.dataArray.cliente.ID);
        this.currencySelected(false, this.dataArray.moneda.ID);
        this.detailForm.controls['name'].setValue('Cliente');
        this.detailForm.controls['date'].setValue(
          new Date(
            moment(this.dataArray.fecha)
              .utcOffset('+0000')
              .format('YYYY-MM-DD HH:MM')
          )
        );
        this.detailForm.controls['expiringDate'].setValue(
          new Date(
            moment(this.dataArray.fechaVto)
              .utcOffset('+0000')
              .format('YYYY-MM-DD HH:MM')
          )
        );
        this.detailForm.controls['transactionType'].setValue(
          this.dataArray.tipo
        );
        this.detailForm.controls['salesPoint'].setValue(
          this.dataArray.puntoVenta.ID
        );
        this.detailForm.controls['xubioInvoiceNumber'].setValue(
          this.dataArray.numeroDocumento
        );
        this.detailForm.controls['paymentCondition'].setValue(
          this.dataArray.condicionDePago
        );
        this.detailForm.controls['exchangePrice'].setValue(
          this.dataArray.cotizacion
        );
        this.detailForm.controls['ISOCurrencyCode'].setValue(
          this.dataArray.moneda.ID == -2 ? 'ARS' : 'USD'
        );
        this.detailForm.controls['originalAmount'].setValue(
          this.dataArray.importetotal
        );
        this.detailForm.controls['totalAmount'].setValue(
          this.dataArray.importetotal
        );
        this.detailForm.controls['taxAmount'].setValue(
          this.dataArray.importeImpuestos
        );
        this.detailForm.controls['recordedAmount'].setValue(
          this.dataArray.importeGravado
        );
        this.detailForm.controls['province'].setValue(this.dataArray.provincia);
        this.detailForm.controls['description'].setValue(
          this.dataArray.descripcion
        );
        this.transaccionid = this.dataArray.transaccionid;
        this.jumpXubioState = true;
        this.jumpXubioDisabled = true;

        if (this.dataArray.transaccionProductoItems.length > 0) {
          const invoiceItems = <FormArray>(
            this.detailForm.controls['invoiceItems']
          );
          this.dataArray.transaccionProductoItems.forEach((element: any) => {
            invoiceItems.push(
              this.formBuilder.group({
                product: element.producto.ID,
                costCenter: element.centroDeCosto.ID,
                amount: element.importe,
                vat: element.iva,
                totalAmount: element.total,
                productCount: element.cantidad,
                description: element.descripcion,
              })
            );
          });
        }

        if (
          Object.keys(<FormArray>this.detailForm.controls['invoiceItems'].value)
            .length == 0
        ) {
          this.addFieldValue();
        }
      }
      this.updateStats();
    });

    /**
     * Add new field
     */
    this.addPerceptionValue();
  }

  ngOnInit(): void {
    /**
     * Get Customers
     */
    this.subscription.add(
      this.store
        .pipe(select(customers.selectCustomersLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(customers.selectAllCustomers))
              .subscribe((customers) => (this.itemSelect = customers));
          }
          this.loading = loading;
        })
    );
    this.store
      .pipe(select(customers.selectCustomersError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError('Error al obtener datos', 'Clientes');
        }
        this.error = error;
      });

    /**
     * Get Sales Products
     */
    this.subscription.add(
      this.store
        .pipe(select(salesProducts.selectSalesProductsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(salesProducts.selectAllSalesProducts))
              .subscribe(
                (salesProducts) => (this.itemProducts = salesProducts)
              );
          }
          this.loadingProducts = loading;
        })
    );
    this.store
      .pipe(select(salesProducts.selectSalesProductsError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError(
            'Error al obtener datos',
            'Productos de Venta'
          );
        }
        this.errorProducts = error;
      });

    /**
     * Get Costs Center
     */
    this.subscription.add(
      this.store
        .pipe(select(accounts.selectAccountsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store.pipe(select(accounts.selectAllAccounts)).subscribe(
              (accounts) => (
                (this.costsCenterSelect = []),
                (this.costsCenter = accounts),
                this.costsCenter.forEach((element: any) => {
                  this.costsCenterSelect.push({
                    id: element.xubioId,
                    nombre: element.accountName,
                    codigo: element.xubioCode,
                  });
                })
              )
            );
          }
          this.loadingAccounts = loading;
        })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError(
          'Error al obtener datos',
          'Centro de Costos'
        );
      }
      this.error = error;
    });

    /**
     * Get Account Manager
     */
    this.subscription.add(
      this.store
        .pipe(select(accountManager.selectAccountManagerLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(accountManager.selectAllAccountManager))
              .subscribe(
                (accountManager) => (
                  (this.collaborators = []),
                  this.collaborators.push({
                    id: 0,
                    nationalityId: 0,
                    fullName: 'Ninguno',
                  }),
                  accountManager.forEach((element: any) => {
                    this.collaborators.push({
                      id: element.id,
                      nationalityId: element.nationalID,
                      fullName: element.firstName + ' ' + element.lastName,
                    });
                  })
                )
              );
          }
          this.loadingAccounts = loading;
        })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError(
          'Error al obtener datos',
          'Centro de Costos'
        );
      }
      this.error = error;
    });

    /**
     * Get Sales Points
     */
    this.subscription.add(
      this.store
        .pipe(select(salesPoints.selectSalesPointsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(salesPoints.selectAllSalesPoints))
              .subscribe(
                (salesPoints) => (this.salesPointsSelect = salesPoints)
              );
          }
          this.loadingSalesPoints = loading;
        })
    );
    this.store
      .pipe(select(salesPoints.selectSalesPointsError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError(
            'Error al obtener datos',
            'Puntos de Venta'
          );
        }
        this.error = error;
      });
  }

  /**
   *  Generate array validators form detail
   */
  get getInvoiceItemsForm() {
    return this.detailForm.get('invoiceItems') as FormArray;
  }

  /**
   * Jump Xubio
   */
  jumpXubio(event: any) {
    this.jumpXubioState = event.checked;
  }

  /**
   * Currency Selected
   */
  currencySelected(e: any, id: any) {
    let currencyid: any;
    if (!e) {
      currencyid = id;
    } else {
      currencyid = e;
    }

    const currency = this.currency.find(
      (currency: any) => currency.id === currencyid
    );

    if (currency.ISOCurrencyCode == 'ARS') {
      this.detailForm.controls['exchangePrice'].disable();
      this.detailForm.controls['exchangePrice'].setValue(1);
    } else {
      this.detailForm.controls['exchangePrice'].enable();
      this.detailForm.controls['exchangePrice'].setValue('');
    }

    if (currency) {
      this.detailForm.controls['ISOCurrencyCode'].setValue(
        currency.ISOCurrencyCode
      );
      this.detailForm.controls['currency'].setValue(currency.id);
      this.currencySelect = (({ ISOCurrencyCode, ...o }) => o)(currency);
    }
  }

  /**
   * CollaboratorsSelected
   */
  collaboratorsSelected(e: any) {
    const collaborator = this.collaborators.find(
      (collaborators: any) => collaborators.id === e
    );
    this.collaboratorsSelect = collaborator;
  }

  /**
   * Customer Selected
   */
  customerSelected(e: any, id: any) {
    let cliente_id;
    if (!e) {
      cliente_id = id;
    } else {
      cliente_id = e;
    }
    this.detailForm.controls['customer'].setValue(cliente_id);
    this.store.dispatch(
      new CustomersLoadByIdAction(<CustomerByIdParams>{
        cliente_id: cliente_id,
      })
    );
    this.subscription.add(
      this.store
        .pipe(select(customers.selectCustomerLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store.pipe(select(customers.selectStateCustomer)).subscribe(
              (customer) =>
                (this.customerSelect = {
                  nombre: customer.customer.nombre,
                  codigo: Utils.replaceString(customer.customer.nombre),
                  id: customer.customer.cliente_id,
                  billingAddress: customer.customer.direccion,
                  country: customer.customer.pais,
                  province: customer.customer.provincia,
                })
            );
          }
          this.loadingCustomer = loading;
        })
    );
    this.store
      .pipe(select(customers.selectCustomerError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError('Error al obtener datos', 'Clientes');
        }
        this.errorCustomer = error;
      });
  }

  /**
   * Calculate imports
   * @param e
   * @param index
   */
  calculateImport(index: number) {
    const control = <FormArray>this.detailForm.controls['invoiceItems'];
    const vat =
      control.value[index]['productCount'] *
      control.value[index]['amount'] *
      control.value[index]['vat'];
    control.value[index]['totalAmount'] =
      control.value[index]['productCount'] * control.value[index]['amount'] +
      vat;
    this.detailForm.controls['invoiceItems'].patchValue(control.value);
    this.updateStats();
  }

  /**
   * Update stats footer
   */
  updateStats() {
    this.totalGross = 0;
    this.totalTax = 0;
    this.total = 0;
    <FormArray>this.detailForm.controls['invoiceItems'].value.forEach(
      (element: any) => {
        this.totalGross =
          Number(element.amount) * Number(element.productCount) +
          Number(this.totalGross);
        this.total = Number(element.totalAmount) + Number(this.total);
        this.totalTax =
          this.totalTax +
          (Number(element.totalAmount) / (1 + Number(element.vat))) *
            Number(element.vat);
      }
    );
    this.totalGrossFooter = `$ ${new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'USD',
    })
      .format(this.totalGross)
      .replace('$', '')}`;
    this.totalTaxFooter = `$ ${new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'USD',
    })
      .format(this.totalTax)
      .replace('$', '')}`;
    this.totalFooter = `$ ${new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'USD',
    })
      .format(this.total)
      .replace('$', '')}`;
    //
    this.detailForm.controls['originalAmount'].setValue(this.totalGross);
    this.detailForm.controls['taxAmount'].setValue(this.totalTax);
    this.detailForm.controls['totalAmount'].setValue(this.total);
  }

  /**
   * Send Form Detail
   */
  sendFormDetail() {
    swal
      .fire({
        title: 'Aviso',
        html: `La factura <b>${this.detailForm.controls['xubioInvoiceNumber'].value}</b> se importará a la base de datos.`,
        width: 300,
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Volver ',
      })
      .then(async (result) => {
        if (result.value) {
          /**
           * Set values Form Detail
           */
          this.detailForm.controls['customer'].setValue(this.customerSelect);
          const control = <FormArray>this.detailForm.controls['customer'];
          if (control.value.province) {
            this.detailForm.controls['province'].setValue(
              control.value.province
            );
          }

          this.detailForm.controls['accountManager'].setValue(`[${this.collaboratorsSelect.id},${this.collaboratorsSelect.nationalityId}]`);
          this.detailForm.controls['currency'].setValue(this.currencySelect);
          this.detailForm.controls['date'].setValue(
            this.datePipe.transform(this.detailForm.value.date, 'yyyy-MM-dd')
          );
          this.detailForm.controls['expiringDate'].setValue(
            this.datePipe.transform(
              this.detailForm.value.expiringDate,
              'yyyy-MM-dd'
            )
          );

          let puntoVentaId: any;
          if (this.dataArray.length) {
            puntoVentaId = this.dataArray.puntoVenta;
          } else {
            puntoVentaId = this.detailForm.controls['salesPoint'].value;
          }
          this.detailForm.controls['salesPoint'].setValue(
            this.salesPointsSelect.find(
              (salesPoint: any) => salesPoint.puntoVentaId === puntoVentaId
            )
          );

          const invoiceItemsControl = <FormArray>(
            this.detailForm.controls['invoiceItems']
          );
          invoiceItemsControl.value.forEach((element: any, index: number) => {
            invoiceItemsControl.value[index]['product'] =
              this.itemProducts.find(
                (product: any) => product.productoid === element.product
              );
            invoiceItemsControl.value[index]['costCenter'] =
              this.costsCenterSelect.find(
                (costCenter: any) => costCenter.id === element.costCenter
              );
            invoiceItemsControl.value[index]['vat'] =
              invoiceItemsControl.value[index]['totalAmount'] -
              invoiceItemsControl.value[index]['amount'];
            this.detailForm.controls['invoiceItems'].patchValue(
              invoiceItemsControl.value
            );
          });

          /**
           * Invoice
           */
          this.store.dispatch(
            new InvoiceLoadAction(<InvoiceParams>{
              body: this.detailForm.value,
              skipXubio: this.jumpXubioState,
              transaccionid: this.transaccionid,
            })
          );
          this.store
            .pipe(select(invoice.selectInvoiceLoading))
            .subscribe((loading) => {
              if (this.loading) {
                this.store
                  .pipe(select(invoice.selectInvoiceResponse))
                  .subscribe((response) => {
                    if (response.invoiceRaw && response.invoiceRds) {
                      this.resetForm();
                      this.updateStats();
                      this.detailForm.controls['date'].setValue(new Date());
                      this.detailForm.controls['expiringDate'].setValue(
                        Utils.addBusinessDays(10)
                      );
                      if (
                        Object.keys(
                          <FormArray>(
                            this.detailForm.controls['invoiceItems'].value
                          )
                        ).length == 0
                      ) {
                        this.addFieldValue();
                      }
                      this.transaccionid = null;
                    }
                  });
              }
              this.loading = loading;
            });
          this.store
            .pipe(select(invoice.selectInvoiceError))
            .subscribe((error) => {
              this.errorInvoice = error;
            });
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Cancel
        }
      });
  }

  /**
   * Reset all form
   */
  resetForm() {
    this.detailForm.reset();
    (this.detailForm.controls['invoiceItems'] as FormArray).clear();
    this.addFieldValue();
    this.perceptionArray = [];
    this.addPerceptionValue();
  }

  /**
   * Add new field
   */
  addFieldValue() {
    const control = <FormArray>this.detailForm.controls['invoiceItems'];
    control.push(this.formBuilder.group(types.invoiceItems));
  }

  addPerceptionValue() {
    this.perceptionArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  /**
   * Delete field by index
   * @param index
   */
  deleteFieldValue(index: number) {
    const control = <FormArray>this.detailForm.controls['invoiceItems'];
    control.removeAt(index);
    this.updateStats();
  }

  deletePerceptionValue(index: any) {
    this.perceptionArray.splice(index, 1);
  }
}
