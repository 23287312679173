import { ActionReducerMap } from '@ngrx/store';
import { GlobalState } from './states/global.state';

import { buysReducer } from './reducers/buys.reducers';
import { salesReducer } from './reducers/sales.reducers';
import { providersReducer } from './reducers/providers.reducers';
import { providerReducer } from './reducers/providers.reducers';
import { customersReducer } from './reducers/customers.reducers';
import { customerReducer } from './reducers/customers.reducers';
import { accountsReducer } from './reducers/accounts.reducers';
import { accountManagerReducer } from './reducers/account-manager.reducers';
import { salesPointsReducer } from './reducers/sales-points.reducers';
import { salesProductsReducer } from './reducers/sales-products.reducers';
import { purchaseProductsReducer } from './reducers/purchase-products.reducers';
import { invoiceReducer } from './reducers/invoice.reducers';
import { costReducer } from './reducers/cost.reducers';
import { salariesReducer } from './reducers/salaries.reducers';

export const reducers: ActionReducerMap<GlobalState> = {
  buys : buysReducer,
  sales : salesReducer,
  providers : providersReducer,
  provider : providerReducer,
  customers : customersReducer,
  customer : customerReducer,
  accounts : accountsReducer,
  accountManager : accountManagerReducer,
  salesPoints : salesPointsReducer,
  salesProducts : salesProductsReducer,
  purchaseProducts : purchaseProductsReducer,
  invoice : invoiceReducer,
  cost : costReducer,
  salaries : salariesReducer,
};
