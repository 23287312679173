import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { CustomersService } from 'src/app/services/service.index';
import { CustomersParams } from 'src/app/models/customers/customers-params';
import { CustomerByIdParams } from 'src/app/models/customers/customerById-params';
import * as customers from '../actions/customers.actions';

@Injectable()
export class CustomersEffects {
  constructor(
    private _customersService: CustomersService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
        ofType<customers.CustomersLoadAction>(customers.CustomersActionType.Loading),
        map((action) => action.payload),
        switchMap((params) =>
        this._customersService.getAllCustomers().pipe(
          map((response : any) => new customers.CustomersLoadSuccessAction(response)),
          catchError((error) => of(new customers.CustomersLoadFailAction(error)))
        )
      )
    )
  );
 
  public loadCustomerById$ = createEffect(() =>
    this.actions$.pipe(
        ofType<customers.CustomersLoadByIdAction>(customers.CustomersActionType.LoadingById),
        map((action) => action.payload),
        switchMap((params: CustomerByIdParams) =>
        this._customersService.getCustomerById(params.cliente_id).pipe(
          map((response : any) => new customers.CustomersLoadByIdSuccessAction(response)),
          catchError((error) => of(new customers.CustomersLoadByIdFailAction(error)))
        )
      )
    )
  );
}