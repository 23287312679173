import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Accounts } from '../../models/accounts/accounts';

export interface StateAccounts extends EntityState<Accounts> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const accountsAdapter: EntityAdapter<Accounts> = createEntityAdapter<Accounts>({
  // 
});

export const initialStateAccounts: StateAccounts = accountsAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
