import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Invoice } from '../../models/invoice/invoice';

export interface StateInvoice extends EntityState<Invoice> {
  error: boolean;
  errorGet: boolean;
  invoices: any;
  getInvoices: any,
  loading: boolean;
  loadingGetInvoice: boolean;
  total: number;
}

export const invoiceAdapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>({
  // 
});

export const initialStateInvoice: StateInvoice = invoiceAdapter.getInitialState({
  error: false,
  errorGet: false,
  invoices: {},
  getInvoices: {},
  loading: true,
  loadingGetInvoice: true,
  total: 0
});
