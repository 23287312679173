import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AWS } from "../../config/aws";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AwsCognitoService {
  constructor(
    private http: HttpClient
  ) {
    // 
  }
 
  public getTokenDetails(callbackCode: string): Observable<any> {
    const details : any = {
      grant_type: 'authorization_code',
      code: callbackCode,
      scope: 'openid+profile',
      redirect_uri: AWS.redirectURL,
      client_id: AWS.client,
    };
    const formBody = Object.keys(details)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`
      )
      .join('&');

    return this.http.post<any>(AWS.cognitoTokenURL, formBody, {
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
  
  public refreshToken(refreshToken:string): Observable<any> {
    const details : any = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: AWS.client,
    };
    const formBody = Object.keys(details)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`
      )
      .join('&');

    return this.http.post<any>(AWS.cognitoTokenURL, formBody, {
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });
  }
}
