import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// import { ADD_BUY, FILTER_BUY_DATE } from 'src/app/store/actions/buys.actions';
import * as XLSX from 'xlsx';

// Sweet alert
import swal from 'sweetalert2';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements OnInit {
  @Input() icon: string = '';
  @Input() name: string = '';
  @Input() status: any;
  @Input() table: any = [];
  @Input() selection: any = [];

  constructor(private router: Router) {
    //
  }

  ngOnInit(): void {
    //
  }

  editVoucher() {
    this.selection.selected.forEach((element: any) => {
      this.router.navigate(['/admin/edit-voucher/' + element.position]);
    });
  }

  deleteItem() {
    swal
      .fire({
        title: 'Aviso',
        text: 'Los comprobantes seleccionados serán eliminados. Desea continuar? ',
        showCancelButton: true,
        width: 300,
        confirmButtonText: 'Borrar comprobante',
        cancelButtonText: 'Volver ',
        customClass: {
          title: 'red-link-swal',
          confirmButton: 'red-btn-swal',
          cancelButton: 'red-link-swal'
        },
      })
      .then((result) => {
        if (result.value) {
          // Delete
          // this.selection.selected.forEach(item => {
          //   let index: number = this.data.findIndex(d => d === item);
          //   console.log(this.data.findIndex(d => d === item));
          //   this.data.splice(index,1)
          //   this.dataSource = new MatTableDataSource<Element>(this.data);
          // });
          // this.selection = new SelectionModel<Element>(true, []);
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Cancel
        }
      });
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      this.table?.nativeElement
    ); // Converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    swal
      .fire({
        title: 'Descargar .xls',
        text: `Se generá un archivo .xls para ${this.selection.selected.length} comprobantes.`,
        showCancelButton: true,
        width: 300,
        confirmButtonText: 'Descargar',
        cancelButtonText: 'Volver ',
      })
      .then((result) => {
        if (result.value) {
          // Save to file
          XLSX.writeFile(wb, 'historial_compras.xlsx');
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Cancel
        }
      });
  }
}
