import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-square',
  templateUrl: './button-square.component.html',
  styleUrls: ['./button-square.component.scss'],
})
export class ButtonSquareComponent implements OnInit {

  @Input() item: any = [];

  constructor() {
    // 
  }
  
  ngOnInit(): void {
    // 
  }

}
