
import { Action } from '@ngrx/store'
import { ProvidersParams } from '../../models/providers/providers-params';
import { ProvidersResponse } from '../../models/providers/providers-response';

import { ProviderByIdParams } from '../../models/providers/providerById-params';
import { ProviderByIdResponse } from '../../models/providers/providerById-response';

export enum ProvidersActionType {
  Loading = '[Providers] Loading',
  LoadSuccess = '[Providers] LoadSuccess',
  LoadFailure = '[Providers] LoadFailure',
  LoadingById = '[ProviderInfo] Loading',
  LoadSuccessById = '[ProviderInfo] LoadSuccess',
  LoadFailureById = '[ProviderInfo] LoadFailure'
}

/**
 * All Providers
 */

export class ProvidersLoadAction implements Action {
  public readonly type = ProvidersActionType.Loading;
  constructor(public payload: ProvidersParams) {}
}

export class ProvidersLoadSuccessAction implements Action {
  public readonly type = ProvidersActionType.LoadSuccess;
  constructor(public payload: ProvidersResponse) {}
}

export class ProvidersLoadFailAction implements Action {
  public readonly type = ProvidersActionType.LoadFailure;
  constructor(public error: any) {}
}

/**
 * Specific Provider by id
 */

export class ProvidersLoadByIdAction implements Action {
  public readonly type = ProvidersActionType.LoadingById;
  constructor(public payload: ProviderByIdParams) {}
}

export class ProvidersLoadByIdASuccessAction implements Action {
  public readonly type = ProvidersActionType.LoadSuccessById;
  constructor(public payload: ProviderByIdResponse) {}
}

export class ProvidersLoadByIdFailAction implements Action {
  public readonly type = ProvidersActionType.LoadFailureById;
  constructor(public error: any) {}
}

export type ProvidersAction = 
  ProvidersLoadAction | 
  ProvidersLoadSuccessAction |
  ProvidersLoadFailAction |
  ProvidersLoadByIdAction | 
  ProvidersLoadByIdASuccessAction |
  ProvidersLoadByIdFailAction;