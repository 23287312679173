import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateSalesProducts, salesProductsAdapter } from '../../store/states/sales-products.state';

export const {
  selectIds: _selectSalesProductsDataIds,
  selectEntities: _selectSalesProductsEntities,
  selectAll: _selectAllSalesProducts,
  selectTotal: _selectSalesProductsTotal
} = salesProductsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateSalesProducts>('salesProducts');

export const selectSalesProductsIds = createSelector(
  selectState,
  _selectSalesProductsDataIds
);

export const selectSalesProductsEntities = createSelector(
  selectState,
  _selectSalesProductsEntities
);

export const selectAllSalesProducts = createSelector(
  selectState,
  _selectAllSalesProducts
);

export const selectSalesProductsError = createSelector(
  selectState,
  (state: StateSalesProducts): boolean => state.error
);

export const selectSalesProductsLoading = createSelector(
  selectState,
  (state: StateSalesProducts): boolean => state.loading
);

export const selectSalesProductsTotal = createSelector(
  selectState,
  (state: StateSalesProducts): number => state.total
);
