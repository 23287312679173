import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { CustomersLoadAction } from 'src/app/store/actions/customers.actions';
import { CustomersParams } from 'src/app/models/customers/customers-params';

import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

import { SalesProductsLoadAction } from 'src/app/store/actions/sales-products.actions';
import { SalesProductsParams } from 'src/app/models/sales-products/sales-products-params';

import { SalesPointsLoadAction } from 'src/app/store/actions/sales-points.actions';
import { SalesPointsParams } from 'src/app/models/sales-points/sales-points-params';

import { AccountManagerLoadAction } from 'src/app/store/actions/account-manager.actions';
import { AccountManagerParams } from 'src/app/models/account-manager/account-manager-params';

// Services
import { SharedService, AccountManagerService, CustomersService } from 'src/app/services/service.index';

@Component({
  selector: 'app-receipt-sale',
  templateUrl: './receipt-sale.component.html',
  styleUrls: ['./receipt-sale.component.scss'],
})
export class ReceiptSaleComponent implements OnInit {
  statusLeftBar:boolean = false;

  constructor(
    private _sharedService: SharedService,
    private _accountManagerService: AccountManagerService,
    private _customersService: CustomersService,
    public store: Store<GlobalState>
  ) {
    this._sharedService.changeTitile('Comprobantes de Venta');
    this._sharedService.changeStatusRowMenu(true);
  }

  ngOnInit(): void {
    this.loadCustomers();
    this.loadAccounts();
    this.loadSalesProducts();
    this.loadSalesPoints();
    this.loadAccountManager();
  }

  /**
   * Change Open or Close left bar
   */
   changeStatusLeftBar(): void {
    this.statusLeftBar = !this.statusLeftBar;
    this._sharedService.changeStatusLeftBar(this.statusLeftBar)
  }

  /**
   * Customers
   */
  private loadCustomers(): void {
    this.store.dispatch(new CustomersLoadAction(<CustomersParams>{}));
  }

  /**
   * Costs Center
   */
  private loadAccounts(): void {
    this.store.dispatch(
      new AccountsLoadAction(<AccountsParams>{ accountType: 'income' })
    );
  }

  /**
   * Sales Products
   */
  private loadSalesProducts(): void {
    this.store.dispatch(new SalesProductsLoadAction(<SalesProductsParams>{}));
  }

  /**
   * Sales Points
   */
  private loadSalesPoints(): void {
    this.store.dispatch(new SalesPointsLoadAction(<SalesPointsParams>{}));
  }

  /**
   * Account Manager
   */
  private loadAccountManager(): void {
    this.store.dispatch(new AccountManagerLoadAction(<AccountManagerParams>{}));
  }
}
