import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PurchaseProducts } from '../../models/purchase-products/purchase-products';

export interface StatePurchaseProducts extends EntityState<PurchaseProducts> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const purchaseProductsAdapter: EntityAdapter<PurchaseProducts> = createEntityAdapter<PurchaseProducts>({
  selectId: (purchaseProducts: PurchaseProducts) => purchaseProducts.productoid
});

export const initialStatePurchaseProducts: StatePurchaseProducts = purchaseProductsAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
