
import { Action } from '@ngrx/store'
import { CustomersParams } from '../../models/customers/customers-params';
import { CustomersResponse } from '../../models/customers/customers-response';

import { CustomerByIdParams } from '../../models/customers/customerById-params';
import { CustomerByIdResponse } from '../../models/customers/customerById-response';

export enum CustomersActionType {
  Loading = '[Customers] Loading',
  LoadSuccess = '[Customers] LoadSuccess',
  LoadFailure = '[Customers] LoadFailure',
  LoadingById = '[CustomerInfo] Loading',
  LoadSuccessById = '[CustomerInfo] LoadSuccess',
  LoadFailureById = '[CustomerInfo] LoadFailure'
}

/**
 * All Customers
 */

export class CustomersLoadAction implements Action {
  public readonly type = CustomersActionType.Loading;
  constructor(public payload: CustomersParams) {}
}

export class CustomersLoadSuccessAction implements Action {
  public readonly type = CustomersActionType.LoadSuccess;
  constructor(public payload: CustomersResponse) {}
}

export class CustomersLoadFailAction implements Action {
  public readonly type = CustomersActionType.LoadFailure;
  constructor(public error: any) {}
}

/**
 * Specific Customer by id
 */

export class CustomersLoadByIdAction implements Action {
  public readonly type = CustomersActionType.LoadingById;
  constructor(public payload: CustomerByIdParams) {}
}

export class CustomersLoadByIdSuccessAction implements Action {
  public readonly type = CustomersActionType.LoadSuccessById;
  constructor(public payload: CustomerByIdResponse) {}
}

export class CustomersLoadByIdFailAction implements Action {
  public readonly type = CustomersActionType.LoadFailureById;
  constructor(public error: any) {}
}

export type CustomersAction = 
  CustomersLoadAction | 
  CustomersLoadSuccessAction |
  CustomersLoadFailAction |
  CustomersLoadByIdAction | 
  CustomersLoadByIdSuccessAction |
  CustomersLoadByIdFailAction;