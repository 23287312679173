import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  StateCustomers,
  StateCustomerById,
  customersAdapter,
  customerByIdAdapter,
} from '../../store/states/customers.state';

export const {
  selectIds: _selectCustomersDataIds,
  selectEntities: _selectCustomersEntities,
  selectAll: _selectAllCustomers,
  selectTotal: _selectCustomersTotal,
} = customersAdapter.getSelectors();

export const selectState = createFeatureSelector<StateCustomers>('customers');

/**
 * All Customers
 */

export const selectCustomersIds = createSelector(
  selectState,
  _selectCustomersDataIds
);

export const selectCustomersEntities = createSelector(
  selectState,
  _selectCustomersEntities
);

export const selectAllCustomers = createSelector(
  selectState,
  _selectAllCustomers
);

export const selectCustomersError = createSelector(
  selectState,
  (state: StateCustomers): boolean => state.error
);

export const selectCustomersLoading = createSelector(
  selectState,
  (state: StateCustomers): boolean => state.loading
);

export const selectCustomersTotal = createSelector(
  selectState,
  (state: StateCustomers): number => state.total
);

/**
 * Specific Customer by id
 */

export const selectStateCustomer =
  createFeatureSelector<StateCustomerById>('customer');

export const selectCustomerError = createSelector(
  selectStateCustomer,
  (stateCustomer: StateCustomerById): boolean => stateCustomer.error
);

export const selectCustomerLoading = createSelector(
  selectStateCustomer,
  (stateCustomer: StateCustomerById): boolean => stateCustomer.loading
);
