import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Providers } from '../../models/providers/providers';
import { ProviderById } from '../../models/providers/providerById';

export interface StateProviders extends EntityState<Providers> {
  error: boolean;
  loading: boolean;
  total: number;
}

/**
 * All Providers
 */

export const providersAdapter: EntityAdapter<Providers> = createEntityAdapter<Providers>({
  selectId: (providers: Providers) => providers.proveedorid
});

export const initialStateProviders: StateProviders = providersAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});

/**
 * Specific Provider by id
 */

export interface StateProviderById {
  provider: any;
  error: boolean;
  loading: boolean;
}

export const providerByIdAdapter: EntityAdapter<ProviderById> = createEntityAdapter<ProviderById>({});

export const initialStateProviderById: StateProviderById = providerByIdAdapter.getInitialState({
  provider: {},
  error: false,
  loading: true,
});
