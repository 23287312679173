import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Cost } from '../../models/cost/cost';

export interface StateCost extends EntityState<Cost> {
  error: boolean;
  errorGet: boolean;
  costs: any;
  getCosts: any,
  loading: boolean;
  loadingGetCost: boolean;
  total: number;
}

export const costAdapter: EntityAdapter<Cost> = createEntityAdapter<Cost>({
  // 
});

export const initialStateCost: StateCost = costAdapter.getInitialState({
  error: false,
  errorGet: false,
  costs: {},
  getCosts: {},
  loading: true,
  loadingGetCost: true,
  total: 0
});
