import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { ProvidersLoadAction } from 'src/app/store/actions/providers.actions';
import { ProvidersParams } from 'src/app/models/providers/providers-params';
import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-edit-voucher',
  templateUrl: './edit-voucher.component.html',
  styleUrls: ['./edit-voucher.component.scss']
})
export class EditVoucherComponent implements OnInit {
  public routeParams = this.route.snapshot.paramMap;
  public productIdFromRoute = Number(this.routeParams.get('voucherId'));

  constructor(
    private _sharedService: SharedService,
    public route: ActivatedRoute,
    public store: Store<GlobalState>
  ) {
    this._sharedService.changeTitile('Editar Comprobante');
    this._sharedService.changeStatusRowMenu(false,true);
  }

  ngOnInit(): void {
    this.loadProviders();
    this.loadAccounts();
  }

  /**
   * Providers
   */
   private loadProviders(): void {
    this.store.dispatch(new ProvidersLoadAction(<ProvidersParams>{}));
  }
  
  /**
   * Costs Center
   */
  private loadAccounts(): void {
    this.store.dispatch(new AccountsLoadAction(<AccountsParams>{accountType : 'outcome'}));
  }
}

