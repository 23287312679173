import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
  StateProviders,
  StateProviderById,
  providersAdapter,
  providerByIdAdapter,
} from '../../store/states/providers.state';

export const {
  selectIds: _selectProvidersDataIds,
  selectEntities: _selectProvidersEntities,
  selectAll: _selectAllProviders,
  selectTotal: _selectProvidersTotal
} = providersAdapter.getSelectors();

export const selectState = createFeatureSelector<StateProviders>('providers');

/**
 * All Providers
 */

export const selectProvidersIds = createSelector(
  selectState,
  _selectProvidersDataIds
);

export const selectProvidersEntities = createSelector(
  selectState,
  _selectProvidersEntities
);

export const selectAllProviders = createSelector(
  selectState,
  _selectAllProviders
);

export const selectProvidersError = createSelector(
  selectState,
  (state: StateProviders): boolean => state.error
);

export const selectProvidersLoading = createSelector(
  selectState,
  (state: StateProviders): boolean => state.loading
);

export const selectProvidersTotal = createSelector(
  selectState,
  (state: StateProviders): number => state.total
);

/**
 * Specific Provider by id
 */

export const selectStateProvider =
  createFeatureSelector<StateProviderById>('provider');

export const selectProviderError = createSelector(
  selectStateProvider,
  (stateProvider: StateProviderById): boolean => stateProvider.error
);

export const selectProviderLoading = createSelector(
  selectStateProvider,
  (stateProvider: StateProviderById): boolean => stateProvider.loading
);
