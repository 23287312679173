
import { Action } from '@ngrx/store'
import { SalesProductsParams } from '../../models/sales-products/sales-products-params';
import { SalesProductsResponse } from '../../models/sales-products/sales-products-response';

export enum SalesProductsActionType {
  Loading = '[SalesProducts] Loading',
  LoadSuccess = '[SalesProducts] LoadSuccess',
  LoadFailure = '[SalesProducts] LoadFailure'
}

export class SalesProductsLoadAction implements Action {
  public readonly type = SalesProductsActionType.Loading;
  constructor(public payload: SalesProductsParams) {}
}

export class SalesProductsLoadSuccessAction implements Action {
  public readonly type = SalesProductsActionType.LoadSuccess;
  constructor(public payload: SalesProductsResponse) {}
}

export class SalesProductsLoadFailAction implements Action {
  public readonly type = SalesProductsActionType.LoadFailure;
  constructor(public error: any) {}
}

export type SalesProductsAction = SalesProductsLoadAction | SalesProductsLoadSuccessAction | SalesProductsLoadFailAction;