import * as salesProducts from '../../store/states/sales-products.state';
import { SalesProductsAction, SalesProductsActionType } from '../../store/actions/sales-products.actions';

export function salesProductsReducer(state = salesProducts.initialStateSalesProducts, action: SalesProductsAction | any): salesProducts.StateSalesProducts {
  switch (action.type) {
    case SalesProductsActionType.Loading: {
      return { ...state, loading: true };
    }
    case SalesProductsActionType.LoadSuccess: {
      return salesProducts.salesProductsAdapter.setAll(action.payload.salesProducts, {
        ...state,
        error: false,
        loading: false
      });
    }
    case SalesProductsActionType.LoadFailure: {
      return salesProducts.salesProductsAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}
