import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, finalize, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AwsCognitoService } from '../../services/service.index';

@Injectable()
export class TokenResolverService implements Resolve<any> {
  constructor(
    private location: Location,
    private router: Router,
    private _awsCognitoService: AwsCognitoService
  ) {}

  resolve(): Observable<any | null> {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );
    const code: string = urlParams.get('code');
    if (!code) {
      return of(null);
    }
    return this.getTokenDetails(code).pipe(
      finalize(() => {
        this.location.replaceState(window.location.pathname);
      })
    );
  }

  getTokenDetails(code: string): Observable<any | null> {
    return this._awsCognitoService.getTokenDetails(code).pipe(
      switchMap((response: any) => {
        localStorage.setItem('token', response.id_token);
        localStorage.setItem('refresh_token', response.refresh_token);
        if (response) {
          this.router.navigate(['admin']);
        }
        return of(response);
      }),
      catchError((error) => {
        return error;
      })
    );
  }
}
