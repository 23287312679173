import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AccountManager } from '../../models/account-manager/account-manager';

export interface StateAccountManager extends EntityState<AccountManager> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const accountManagerAdapter: EntityAdapter<AccountManager> = createEntityAdapter<AccountManager>({
  // 
});

export const initialStateAccountManager: StateAccountManager = accountManagerAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
