import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import { CostLoadAction } from 'src/app/store/actions/cost.actions';
import { CostParams } from 'src/app/models/cost/cost-params';

import { ProvidersLoadByIdAction } from 'src/app/store/actions/providers.actions';
import { ProviderByIdParams } from 'src/app/models/providers/providerById-params';

import * as arrays from 'src/hardcoded-values/forms/arrays';
import * as types from './types';
import * as providers from 'src/app/store/selectors/providers.selectors';
import * as accounts from 'src/app/store/selectors/accounts.selectors';
import * as purchaseProducts from 'src/app/store/selectors/purchase-products.selectors';
import * as cost from 'src/app/store/selectors/cost.selectors';
import * as moment from 'moment';

import { DatePipe } from '@angular/common';

// Utils
import Utils from 'src/app/utils/utils';

// Sweet alert
import swal from 'sweetalert2';

// Services
import { SharedService, NotificationService } from 'src/app/services/service.index';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {  
  detailForm: FormGroup;
  dataArray : any = [];

  transaccionid : number = null;
  total : number = 0;
  totalTax : number = 0;
  totalGross : number = 0;
  totalPerception : number = 0;
  notTaxedAmount : number = 0;
  totalFooter : string;
  totalTaxFooter : string;
  totalGrossFooter : string;

  // Selects and Arrays
  itemSelect: any = [];
  itemProducts: any = [];
  vendorSelect: any;
  paymentCondition: any = arrays.paymentCondition;
  currency: any = arrays.currency;
  currencySelect: any;
  transactionType: any = arrays.transactionType;
  providerSelect: any = [];
  ivaSelect: any = arrays.ivaSelect;
  taxPerception: any = arrays.taxPerception;
  costsCenter: any = [];
  costsCenterSelect: any = [];
  jumpXubioState: boolean = false;

  // Arrays and Form demo
  fieldArray: Array<any> = [];
  perceptionArray: Array<any> = [];
  newAttribute: any = {};

  // Providers
  loading?: boolean;
  error?: boolean;
  public error$?: Observable<boolean>;

  // Accounts
  loadingAccounts?: boolean;
  errorAccounts?: boolean;
  errorAccounts$?: Observable<boolean>;

  // Products
  loadingProducts?: boolean;
  errorProducts?: boolean;
  public errorProducts$?: Observable<boolean>;

  // Cost
  loadingCost?: boolean;
  errorCost?: boolean;
  public errorCost$?: Observable<boolean>;

  // Provider
  loadingProvider?: boolean;
  errorProvider?: boolean;
  public errorProvider$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _sharedService : SharedService,
    private _notifyService : NotificationService
  ) {
    /**
     * Generate form datail
     */
     this.detailForm = this.formBuilder.group({
      vendor: [[], Validators.required],
      transactionType: [[], Validators.required],
      name: ['Proveedor'], // Falta
      date: [new Date(), Validators.required],
      expiringDate: [Utils.addBusinessDays(10), Validators.required],
      xubioInvoiceNumber: ['', Validators.required],
      exchangePrice: ['', Validators.required],
      paymentCondition: [[], Validators.required],
      fiscalDate: [new Date(), Validators.required],
      ISOCurrencyCode: [''],
      currency: [[], Validators.required],
      originalAmount: [''],
      totalAmount: [''],
      taxAmount: [''],
      recordedAmount: [0],
      province: [''],
      description: ['Factura Proveedor'], // Falta
      costItems: this.formBuilder.array([
        this.formBuilder.group(
          types.costItems
        )
      ]),
      perceptionItems: this.formBuilder.array([
        this.formBuilder.group(
          types.perceptionItems
        )
      ]),
    });

    /**
     * Start
    */
    this.updateStats();
    
    /**
     * Get detail order
     */
    this._sharedService.getDetailOrder$().subscribe((data) => {
      if(Object.keys(data).length > 0){
        this.resetForm();

        this.dataArray = data;

        this.providerSelected(false, this.dataArray.proveedor.ID); 
        this.currencySelected(false, this.dataArray.moneda.ID); 
        this.detailForm.controls['name'].setValue('Proveedor');
        this.detailForm.controls['transactionType'].setValue(this.dataArray.tipo);
        this.detailForm.controls['vendor'].setValue(this.dataArray.proveedor.ID);
        this.detailForm.controls['date'].setValue(new Date(moment(this.dataArray.fecha).utcOffset('+0000').format('YYYY-MM-DD HH:MM')));
        this.detailForm.controls['expiringDate'].setValue(new Date(moment(this.dataArray.fechaVto).utcOffset('+0000').format('YYYY-MM-DD HH:MM')));
        this.detailForm.controls['xubioInvoiceNumber'].setValue(this.dataArray.numeroDocumento);
        this.detailForm.controls['paymentCondition'].setValue(this.dataArray.condicionDePago);
        this.detailForm.controls['fiscalDate'].setValue(new Date(moment(this.dataArray.fechaComprobante).utcOffset('+0000').format('YYYY-MM-DD HH:MM')));
        this.detailForm.controls['ISOCurrencyCode'].setValue(this.dataArray.moneda.ID == -2 ? 'ARS' : 'USD');
        this.detailForm.controls['originalAmount'].setValue(this.dataArray.importetotal);
        this.detailForm.controls['totalAmount'].setValue(this.dataArray.importetotal);
        this.detailForm.controls['taxAmount'].setValue(this.dataArray.importeImpuestos);
        this.detailForm.controls['recordedAmount'].setValue(this.dataArray.importeGravado);
        this.detailForm.controls['province'].setValue(this.dataArray.provincia);
        this.detailForm.controls['description'].setValue(this.dataArray.descripcion);
        this.transaccionid = this.dataArray.transaccionid;
        this.jumpXubioState = true;

        if(this.dataArray.transaccionProductoItems.length > 0){
          const costItems = <FormArray>this.detailForm.controls['costItems'];
          this.dataArray.transaccionProductoItems.forEach((element: any) => {
            costItems.push(this.formBuilder.group({
                product: element.producto.ID,
                costCenter: element.centroDeCosto.ID,
                amount: element.importe,
                vat: element.iva,
                notTaxedAmount : element.montoExtento,
                totalAmount: element.total,
                productCount: element.cantidad,
                description: element.descripcion,
              })
            )}
          );
        }

        if(this.dataArray.transaccionPercepcionItems.length > 0){
          const perceptionItems = <FormArray>this.detailForm.controls['perceptionItems'];
          this.dataArray.transaccionPercepcionItems.forEach((element: any) => {
            perceptionItems.push(this.formBuilder.group({
                amount: element.importe,
                taxPerception: element.percepcionImpuesto.ID,
              })
            )}
          );
        }
      
        if(Object.keys(<FormArray>this.detailForm.controls['costItems'].value).length == 0){
          this.addFieldValue();
        }
        if(Object.keys(<FormArray>this.detailForm.controls['perceptionItems'].value).length == 0){
          this.addPerceptionValue();
        }    
      }
      this.updateStats();
    });    
  }

  ngOnInit(): void {
    /**
    * Get Providers
    */  
    this.subscription.add(
      this.store.pipe(select(providers.selectProvidersLoading)).subscribe((loading) => {
        if(loading){
          this.store.pipe(select(providers.selectAllProviders)).subscribe((providers) => (this.itemSelect = providers));
        }
        this.loading = loading;
      })
    );
    this.store.pipe(select(providers.selectProvidersError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Proveedores");
      }
      this.error = error;
    })

    /**
    * Get Purchase Products
    */  
    this.subscription.add(
      this.store.pipe(select(purchaseProducts.selectPurchaseProductsLoading)).subscribe((loading) => {
        if(loading){
          this.store.pipe(select(purchaseProducts.selectAllPurchaseProducts)).subscribe((purchaseProducts) => (this.itemProducts = purchaseProducts));
        }
        this.loadingProducts = loading;
      })
    );
    this.store.pipe(select(purchaseProducts.selectPurchaseProductsError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Productos de Compra");
      }
      this.errorProducts = error;
    })        
  
    /**
     * Get Costs Center
     */
     this.subscription.add(
      this.store.pipe(select(accounts.selectAccountsLoading)).subscribe((loading) => {
        if(loading){
          this.store.pipe(select(accounts.selectAllAccounts)).subscribe((accounts) => (
            this.costsCenterSelect = [],
            this.costsCenter = accounts,
            this.costsCenter.forEach((element: any) => {
              this.costsCenterSelect.push({
                id: element.xubioId,
                nombre: element.accountName,
                codigo: element.xubioCode
              })
            })
          ));
        }
        this.loadingAccounts = loading;
      })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Centro de Costos");
      }
      this.error = error;
    })  
  }

  /**
   *  Generate array validators form detail
   */  
  get getcostItemsForm() {
    return this.detailForm.get('costItems') as FormArray;
  }

  get getperceptionItemsForm() {
    return this.detailForm.get('perceptionItems') as FormArray;
  }

  /**
   * Jump Xubio
   */
  jumpXubio(event:any){
    this.jumpXubioState = event.checked;
  }

  /**
   * Currency Selected
   */
  currencySelected(e:any,id:any){
    let currencyid:any;
    if(!e){
      currencyid = id;
    }else{
      currencyid = e;
    }
    
    const currency = this.currency.find((currency:any) => currency.id === currencyid);

    if(currency.ISOCurrencyCode == 'ARS'){
      this.detailForm.controls['exchangePrice'].disable();
      this.detailForm.controls['exchangePrice'].setValue(1);
    }else{
      this.detailForm.controls['exchangePrice'].enable();
      this.detailForm.controls['exchangePrice'].setValue('');
    }

    if (currency) {
      this.detailForm.controls['ISOCurrencyCode'].setValue(currency.ISOCurrencyCode);
      this.detailForm.controls['currency'].setValue(currency.id);
      this.currencySelect = (({ ISOCurrencyCode, ...o }) => o)(currency);
    }
  }

  /**
   * Provider Selected
   */
  providerSelected(e:any,id:any){
    let proveedorid;
    if(!e){
      proveedorid = id;
    }else{
      proveedorid = e;
    }
    this.detailForm.controls['vendor'].setValue(proveedorid);
    this.store.dispatch(new ProvidersLoadByIdAction(<ProviderByIdParams>{proveedorid : proveedorid}));
    this.subscription.add(
      this.store.pipe(select(providers.selectProviderLoading)).subscribe((loading) => {
        if(loading){
          this.store.pipe(select(providers.selectStateProvider)).subscribe((provider) => (
            this.vendorSelect = {
              nombre: provider.provider.nombre,
              codigo: Utils.replaceString(provider.provider.nombre),
              id: provider.provider.proveedorid,
              billingAddress: provider.provider.direccion,
              country: provider.provider.pais,
              province: provider.provider.provincia,
            }
          ));
        }
        this.loadingProvider = loading;
      })
    );
    this.store.pipe(select(providers.selectProviderError)).subscribe((error) => {
      if(error){
        this._notifyService.showError("Error al obtener datos", "Clientes");
      }
      this.errorProvider = error;
    })
  }

  /**
   * Calculate imports
   * @param e 
   * @param index
   */
  calculateImport(index: number){
    const control = <FormArray>this.detailForm.controls['costItems'];
    const vat = (control.value[index]['productCount'] * control.value[index]['amount']) * control.value[index]['vat'];
    control.value[index]['totalAmount'] = (control.value[index]['productCount'] * control.value[index]['amount']) + vat + control.value[index]['notTaxedAmount'];
    this.detailForm.controls['costItems'].patchValue(control.value);
    this.updateStats();
  }
  
  /**
   * Update stats footer
   */
  updateStats(){
    this.totalGross = 0;
    this.totalTax = 0;
    this.total = 0;
    this.notTaxedAmount = 0;
    this.totalPerception = 0;
    this.detailForm.controls['perceptionItems'].value.forEach((element:any) => {
      this.totalPerception = Number(element.amount) + this.totalPerception;
    })
    <FormArray>this.detailForm.controls['costItems'].value.forEach((element:any) => {
      this.totalGross = (Number(element.amount) * Number(element.productCount)) + Number(this.totalGross) + Number(this.notTaxedAmount);
      this.total = Number(element.totalAmount) + Number(this.total);
      this.totalTax = this.totalTax + (((Number(element.totalAmount) - element.notTaxedAmount)  / (1 + Number(element.vat))) * Number(element.vat));
    })
    //
    this.total = this.total + this.totalPerception;
    this.totalTax = this.totalTax + this.totalPerception;
    //
    this.totalGrossFooter = `$ ${new Intl.NumberFormat('de-DE', {style: "currency", currency: "USD"}).format(this.totalGross).replace('$','')}`;
    this.totalTaxFooter = `$ ${new Intl.NumberFormat('de-DE', {style: "currency", currency: "USD"}).format(this.totalTax).replace('$','')}`;
    this.totalFooter = `$ ${new Intl.NumberFormat('de-DE', {style: "currency", currency: "USD"}).format(this.total).replace('$','')}`;
    // 
    this.detailForm.controls['originalAmount'].setValue(this.totalGross);
    this.detailForm.controls['taxAmount'].setValue(this.totalTax);
    this.detailForm.controls['totalAmount'].setValue(this.total);
  }

  /**
   * Send Form Detail
   */
  sendFormDetail(){
    swal.fire({
      title: 'Aviso',
      html: `La factura <b>${this.detailForm.controls['xubioInvoiceNumber'].value}</b> se importará a la base de datos.`,
      width: 300,
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Volver ',
    })
    .then(async (result) => {
      if (result.value) {
        /**
         * Set values Form Detail
         */
        this.detailForm.controls['vendor'].setValue(this.vendorSelect);
        const control = <FormArray>this.detailForm.controls['vendor'];
        if(control.value.province){
          this.detailForm.controls['province'].setValue(control.value.province);
        }

        this.detailForm.controls['currency'].setValue(this.currencySelect);
        this.detailForm.controls['date'].setValue(this.datePipe.transform(this.detailForm.value.date, 'yyyy-MM-dd'));
        this.detailForm.controls['expiringDate'].setValue(this.datePipe.transform(this.detailForm.value.expiringDate, 'yyyy-MM-dd'));
        this.detailForm.controls['fiscalDate'].setValue(this.datePipe.transform(this.detailForm.value.fiscalDate, 'yyyy-MM-dd'));

        const costItemsControl = <FormArray>this.detailForm.controls['costItems'];
        costItemsControl.value.forEach((element:any, index: number) => {
          costItemsControl.value[index]['product'] = this.itemProducts.find((product:any) =>product.productoid === element.product);
          costItemsControl.value[index]['costCenter'] = this.costsCenterSelect.find((costCenter:any) =>costCenter.id === element.costCenter);
          costItemsControl.value[index]['vat'] = costItemsControl.value[index]['totalAmount'] - costItemsControl.value[index]['amount'] - costItemsControl.value[index]['notTaxedAmount'] - this.totalPerception;
          this.detailForm.controls['costItems'].patchValue(costItemsControl.value);
        })
        
        const controlPerceptionItems = <FormArray>this.detailForm.controls['perceptionItems'];
        controlPerceptionItems.value.forEach((element:any, index: number) => {
          if(!controlPerceptionItems.value[index].amount && !controlPerceptionItems.value[index].taxPerception){
            this.detailForm.controls['perceptionItems'].value.splice(0, 1);
          }else{
            controlPerceptionItems.value[index].taxPerception = this.taxPerception.find((taxPerception:any) => taxPerception.id === element.taxPerception);
            this.detailForm.controls['perceptionItems'].patchValue(controlPerceptionItems.value);
          }
        })

        this.store.dispatch(new CostLoadAction(<CostParams>{body : this.detailForm.value, skipXubio : this.jumpXubioState, transaccionid: this.transaccionid}));
        this.store.pipe(select(cost.selectCostLoading)).subscribe((loading) => { 
          if(this.loading){
            this.store.pipe(select(cost.selectCostResponse)).subscribe((response) => {
              if (response.costRaw && response.costRds){
                if (response.costRaw.length >= 1 && response.costRds.length >= 1) {
                  this.resetForm();
                  this.updateStats();
                  this.detailForm.controls['date'].setValue(new Date());
                  this.detailForm.controls['fiscalDate'].setValue(new Date());
                  this.detailForm.controls['expiringDate'].setValue(Utils.addBusinessDays(10));
                  if(Object.keys(<FormArray>this.detailForm.controls['costItems'].value).length == 0){
                    this.addFieldValue();
                  }
                  if(Object.keys(<FormArray>this.detailForm.controls['perceptionItems'].value).length == 0){
                    this.addPerceptionValue();
                  }                
                  this.transaccionid = null;      
                }
              }
            })
          }          
          this.loading = loading 
        })
        this.store.pipe(select(cost.selectCostError)).subscribe((error) => {
          this.errorCost = error;
        })  
      } else if (result.dismiss === swal.DismissReason.cancel) {
        // Cancel
      }
    });
  }

  /**
   * Reset all form
   */
  resetForm(){
    this.detailForm.reset();
    (this.detailForm.controls['costItems'] as FormArray).clear();
    this.addFieldValue();
    (this.detailForm.controls['perceptionItems'] as FormArray).clear();
    this.addPerceptionValue();
  }

  /**
   * Add new field
   */
  addFieldValue() {
    const control = <FormArray>this.detailForm.controls['costItems'];
    control.push(this.formBuilder.group(
        types.costItems
      )
    );
  }

  addPerceptionValue() {
    const control = <FormArray>this.detailForm.controls['perceptionItems'];
    control.push(this.formBuilder.group(
        types.perceptionItems
      )
    );
  }

  /**
   * Delete field by index
   * @param index 
   */
  deleteFieldValue(index: number) {
    const control = <FormArray>this.detailForm.controls['costItems'];
    control.removeAt(index);
    this.updateStats();
  }
  
  deletePerceptionValue(index: any) {
    const control = <FormArray>this.detailForm.controls['perceptionItems'];
    control.removeAt(index);
  }
}
