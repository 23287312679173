<form [formGroup]="formFilter">
    <span>
        Filtar por fecha:
    </span>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="now">
            Hoy
        </mat-button-toggle>
        <mat-button-toggle value="yesterday">
            Ayer
        </mat-button-toggle>
        <mat-button-toggle value="last30">
            Últimos 30 días
        </mat-button-toggle>
        <mat-button-toggle value="last60">
            Últimos 60 días
        </mat-button-toggle>
        <mat-button-toggle value="picker" (click)="picker.open()">
            <mat-icon>event</mat-icon>
            <ng-template #emptyBetween>
                Seleccionar fecha
            </ng-template>
            <ng-container *ngIf="fromDate && toDate; else emptyBetween">
                {{ fromDate | date: 'dd/MM/yyyy' }} - {{ toDate | date: 'dd/MM/yyyy' }}
            </ng-container>
            <mat-date-range-input class="input-date" [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="fromDate">
                <input matEndDate placeholder="End date" formControlName="toDate">
            </mat-date-range-input>
        </mat-button-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-button-toggle-group>
</form>