import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  exhaustMap,
  mergeMap,
} from 'rxjs/operators';
import { InvoiceService } from 'src/app/services/service.index';
import { InvoiceParams } from 'src/app/models/invoice/invoice-params';
import { InvoiceResponse } from 'src/app/models/invoice/invoice-response';
import * as invoice from '../actions/invoice.actions';

// Services
import { NotificationService } from 'src/app/services/service.index';

@Injectable()
export class InvoiceEffects {
  constructor(
    private actions$: Actions,
    private _invoiceService: InvoiceService,
    private _notifyService: NotificationService
  ) {
    //
  }

  public loadInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<invoice.InvoiceLoadAction>(invoice.InvoiceActionType.Loading),
      map((action) => action.payload),
      switchMap((params: InvoiceParams) =>
        this._invoiceService.postInvoice(params.body,params.skipXubio,params.transaccionid).pipe(
          map(
            (response: any) => {
              if(Object.keys(response.invoices).length){
                this._notifyService.showSuccess("El comprobante se ha cargado con exito!", "Invoice");
              }else{
                this._notifyService.showError("Error al enviar datos", "Invoice");
              }
              return new invoice.InvoiceLoadSuccessAction(response)
            }
          ),
          catchError((error) => of(new invoice.InvoiceLoadFailAction(error)))
        )
      )
    )
  );

  public getInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<invoice.InvoiceLoadAction>(invoice.InvoiceActionType.LoadingGetInvoice),
      map((action) => action.payload),
      switchMap((params: InvoiceParams) =>
        this._invoiceService.getInvoice(params.body).pipe(
          map(
            (response: any) => {
              if(Object.keys(response.invoices).length){
                this._notifyService.showSuccess("Datos obtenidos", "Invoice");
              }else{
                this._notifyService.showError("Error al obtener datos", "Invoice");
              }
              return new invoice.InvoiceLoadSuccessGetAction(response)
            }
          ),
          catchError((error) => of(new invoice.InvoiceLoadGetFailAction(error)))
        )
      )
    )
  );
}
