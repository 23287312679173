import { Injectable } from '@angular/core';
import { CONFIG } from "../../config/config";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SalesProductsService {

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { }

  getAllSalesProducts() {
    return this.http.get(`${CONFIG.API_PROD}sales_products`)
  }

}