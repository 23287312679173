<mat-card>
    <app-loading *ngIf="loading"></app-loading>
    <mat-card-content>
        <form [formGroup]="form">
            <p>
                Ingrese un {{placeholder}} y N° de Comprobante para importar los datos desde Xubio.
            </p>
            <p>
                <ng-select placeholder="{{placeholder}} *" (change)="userSelected($event)" appendTo="body">
                    <ng-option *ngFor="let item of itemSelect" [value]="item">
                        {{item.nombre}}
                    </ng-option>
                </ng-select>
            </p>
            <p>
                <mat-form-field appearance="legacy">
                    <mat-label>N° del comprobante *</mat-label>
                    <input matInput placeholder="X - 000 - 000000" formControlName="docNumber">
                </mat-form-field>
            </p>
            <p>
                <button (click)="import()" mat-raised-button class="blue" color="primary">
                    Importar
                </button>
            </p>
            <p>
                <a (click)="reloadCurrentRoute()" mat-flat-button>
                    Nuevo ingreso
                </a>
            </p>
        </form>
    </mat-card-content>
    <div class="box-bulk-load" *ngIf="type == 1">
        <p>
            <button routerLink="/admin/bulk-load" class="simple-primary bulk-load" mat-raised-button>
                Ir a Carga Masiva
            </button>
        </p>
    </div>
</mat-card>