import { Injectable } from '@angular/core';
import { CONFIG } from "../../config/config";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    public http: HttpClient,
    public router: Router,
  ) { }

  getAllCustomers() {
    return this.http.get(`${CONFIG.API_PROD}customers`)
  }

  getCustomerById(id:number) {
    return this.http.get(`${CONFIG.API_PROD}customers/${id}`)
  }
  
}