import { Component, OnInit } from '@angular/core';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public menuTop: any = [];
  public menu: any = [];

  constructor(
    private _sharedService: SharedService
  ) {
    this._sharedService.changeTitile('');
    this._sharedService.changeStatusRowMenu(false, false);

    /**
     * Set params menu top
     */
    this.menuTop = {
      icon: 'dino.svg',
      title: 'DinoCount',
    };

    /**
     * Set buttons dashboard
     */
    this.menu = [
      {
        icon: 'purchase_receipt.svg',
        title: 'Comprobantes de compra',
        link: 'receipt',
        statusNavBar: true,
      },
      {
        icon: 'sales_receipt.svg',
        title: 'Comprobantes de venta',
        link: 'receipt-sale',
        statusNavBar: true,
      },
      {
        icon: 'salary.svg',
        title: 'Importar planilla de sueldos',
        link: 'salaries',
        statusNavBar: false,
      },
      {
        icon: 'tax.svg',
        title: 'Importar planilla de impuestos',
        link: 'taxes',
        statusNavBar: false,
      },
    ];
  }

  ngOnInit(): void {
    //
  }
}
