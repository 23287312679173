<app-provider [type]=2></app-provider>

<div class="hide-left-bar" (click)="changeStatusLeftBar()">
    <span *ngIf="statusLeftBar; else showLeftBar">
        <mat-icon>arrow_right</mat-icon>
    </span>
    <ng-template #showLeftBar>
        <mat-icon>arrow_left</mat-icon>
    </ng-template>
</div>

<app-detail></app-detail>