import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { SalesPointsService } from 'src/app/services/service.index';
import { SalesPointsParams } from 'src/app/models/sales-points/sales-points-params';
import { SalesPointsResponse } from 'src/app/models/sales-points/sales-points-response';
import * as salesPoints from '../actions/sales-points.actions';

@Injectable()
export class SalesPointsEffects {
  constructor(
    private _salesPointsService: SalesPointsService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadSalesPoints$ = createEffect(() =>
    this.actions$.pipe(
        ofType<salesPoints.SalesPointsLoadAction>(salesPoints.SalesPointsActionType.Loading),
        map((action) => action.payload),
        switchMap((params) =>
        this._salesPointsService.getAllSalesPoints().pipe(
          map((response : any) => new salesPoints.SalesPointsLoadSuccessAction(response)),
          catchError((error) => of(new salesPoints.SalesPointsLoadFailAction(error)))
        )
      )
    )
  );
}