
import { Action } from '@ngrx/store'
import { SalesParams } from '../../models/sales/sales-params';
import { SalesResponse } from '../../models/sales/sales-response';

export enum SalesActionType {
  Loading = '[Sales] Loading',
  LoadSuccess = '[Sales] LoadSuccess',
  LoadFailure = '[Sales] LoadFailure'
}

export class SalesLoadAction implements Action {
  public readonly type = SalesActionType.Loading;
  constructor(public payload: SalesParams) {}
}

export class SalesLoadSuccessAction implements Action {
  public readonly type = SalesActionType.LoadSuccess;
  constructor(public payload: SalesResponse) {}
}

export class SalesLoadFailAction implements Action {
  public readonly type = SalesActionType.LoadFailure;
  constructor(public error: any) {}
}

export type SalesAction = SalesLoadAction | SalesLoadSuccessAction | SalesLoadFailAction;
