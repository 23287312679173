import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { SelectionModel } from '@angular/cdk/collections';
import { Store, select } from '@ngrx/store';
import { Observable, merge, Subject, Subscription } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

// NgRx
import * as buys from 'src/app/store/selectors/buys.selectors';
import { Buys } from 'src/app/models/buys/buys';
import { GlobalState } from 'src/app/store/states/global.state';
import { BuysLoadAction } from 'src/app/store/actions/buys.actions';
import { BuysParams } from 'src/app/models/buys/buys-params';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-salaries-sheetload',
  templateUrl: './salaries-sheetload.component.html',
  styleUrls: ['./salaries-sheetload.component.scss']
})
export class SalariesSheetloadComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild('tableBuys') table?: ElementRef;

  displayedColumns: string[] = [
    'select',
    'position',
    'cuil',
    'fullname',
    'type',
    'price',
    'date',
    'costs',
  ];

  btn: any = [];
  dataSource!: MatTableDataSource<Buys>;
  selection = new SelectionModel<Buys>(true, []);
  buysTotal?: number;
  noData: Buys[] = [<Buys>{}];
  loading?: boolean;
  public error$?: Observable<boolean>;
  public filterSubject = new Subject<string>();
  defaultSort: Sort = { active: 'role', direction: 'asc' };

  private filter: string = '';
  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private _sharedService : SharedService
  ) {
    this._sharedService.changeTitile('Planillas Cargadas - Sueldos');
    this._sharedService.changeStatusRowMenu(true);
  }

  public ngOnInit(): void {
    this.store.select((state) => state.buys.loading);
    this.store.pipe(select(buys.selectAllBuys)).subscribe((buys) => this.initializeData(buys));
    this.store.pipe(select(buys.selectBuysTotal)).subscribe((total) => (this.buysTotal = total));
    this.subscription.add(
      this.store.pipe(select(buys.selectBuysLoading)).subscribe((loading) => {
          if (loading) {
            this.dataSource = new MatTableDataSource(this.noData);
          }
          this.loading = loading;
        })
    );
    this.error$ = this.store.pipe(select(buys.selectBuysError));
  }

  public ngAfterViewInit(): void {
    this.loadBuys();
    let filter$ = this.filterSubject.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap((value: string) => {
        this.paginator.pageIndex = 0;
        this.filter = value;
      })
    );

    let sort$ = this.sort.sortChange.pipe(
      tap(() => (this.paginator.pageIndex = 0))
    );

    this.subscription.add(
      merge(filter$, sort$, this.paginator.page)
        .pipe(tap(() => this.loadBuys()))
        .subscribe()
    );
  }

  private loadBuys(): void {
    this.store.dispatch(
      new BuysLoadAction(<BuysParams>{
        filter: this.filter.toLocaleLowerCase(),
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        sortDirection: this.sort.direction,
        sortField: this.sort.active,
      })
    );
  }

  private initializeData(buys: Buys[]): void {
    this.dataSource = new MatTableDataSource(buys.length ? buys : this.noData);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public retry(): void {
    this.loadBuys();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: Buys): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}
