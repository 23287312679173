import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();

  public titleSection: string = '';
  public statusRowMenu: boolean = false;
  public statusBackMenu: boolean = false;

  constructor(
    private _sharedService: SharedService,
    private _location: Location
  ) {
    /**
     * Set title page
     */
    this._sharedService.getTitleSection$().subscribe((titleSection) => {
      this.titleSection = titleSection;
    });

    /**
     * Show/Hide menu go back
     */
    this._sharedService.getStatusRowMenu$().subscribe((statusRowMenu) => {
      this.statusRowMenu = statusRowMenu;
    });
    this._sharedService.getStatusBackMenu$().subscribe((statusBackMenu) => {
      this.statusBackMenu = statusBackMenu;
    });
  }

  ngOnInit(): void {
    //
  }

  /**
   * Open menu
   */
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  /**
   * Go Back
   */
  public back() {
    this._location.back();
  }
}
