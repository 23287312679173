import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// import { SalariesService } from 'src/app/services/service.index';
import { SalariesParams } from 'src/app/models/salaries/salaries-params';
import { SalariesResponse } from 'src/app/models/salaries/salaries-response';
import * as salaries from '../actions/salaries.actions';

@Injectable()
export class SalariesEffects {
  constructor(
    // private _salariesService: SalariesService, 
    private actions$: Actions
  ) {
    // 
  }
 
  // public loadSalaries$ = createEffect(() =>
    // this.actions$.pipe(
      // ofType<salaries.SalariesLoadAction>(salaries.SalariesActionType.Loading),
      // map((action) => action.payload),
      // switchMap((params: SalariesParams) =>
      //   this._salariesService.getSalaries(params).pipe(
      //     map((response: SalariesResponse) => new salaries.SalariesLoadSuccessAction(response)),
      //     catchError((error) => of(new salaries.SalariesLoadFailAction(error)))
      //   )
      // )
    // )
  // );
}
