import { Injectable } from '@angular/core';
import { CONFIG } from '../../config/config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(public http: HttpClient, public router: Router) {}

  postInvoice(data: any, skipXubio: boolean, transaccionid: any) {
    const path = skipXubio ? '?skipXubio=true' : '';
    const headers = { 'Content-Type': 'text/plain' };
    const body = data;
    if (transaccionid) {
      return this.http.put(
        `${CONFIG.API_PROD}invoice/${transaccionid}${path}`,
        body,
        { headers }
      );
    } else {
      return this.http.post(`${CONFIG.API_PROD}invoice${path}`, body, {
        headers,
      });
    }
  }

  postMassiveInvoice(data: any) {
    const headers = { 'Content-Type': 'text/plain' };
    const body = data;
    return this.http.post(`${CONFIG.API_PROD}createMassiveInvoice`, body, {
      headers,
    });
  }

  getInvoice(data: any) {
    const path = `?clientId=${data.clientId}&docNumber=${data.docNumber}`;
    // const path = `?clientId=4268533&docNumber=A-0007-00000313`;
    return this.http.get(`${CONFIG.API_PROD}invoice${path}`);
  }
}
