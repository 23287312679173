import { Component, OnInit } from '@angular/core';

// Services
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-voucher-history',
  templateUrl: './voucher-history.component.html',
  styleUrls: ['./voucher-history.component.scss']
})
export class VoucherHistoryComponent implements OnInit {

  constructor(
    private _sharedService: SharedService, 
  ) {
    this._sharedService.changeTitile('Historial de Comprobantes');
    this._sharedService.changeStatusRowMenu(true);
  }

  ngOnInit(): void {
    // 
  }

}
