import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateSalesPoints, salesPointsAdapter } from '../../store/states/sales-points.state';

export const {
  selectIds: _selectSalesPointsDataIds,
  selectEntities: _selectSalesPointsEntities,
  selectAll: _selectAllSalesPoints,
  selectTotal: _selectSalesPointsTotal
} = salesPointsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateSalesPoints>('salesPoints');

export const selectSalesPointsIds = createSelector(
  selectState,
  _selectSalesPointsDataIds
);

export const selectSalesPointsEntities = createSelector(
  selectState,
  _selectSalesPointsEntities
);

export const selectAllSalesPoints = createSelector(
  selectState,
  _selectAllSalesPoints
);

export const selectSalesPointsError = createSelector(
  selectState,
  (state: StateSalesPoints): boolean => state.error
);

export const selectSalesPointsLoading = createSelector(
  selectState,
  (state: StateSalesPoints): boolean => state.loading
);

export const selectSalesPointsTotal = createSelector(
  selectState,
  (state: StateSalesPoints): number => state.total
);
