import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { AccountManagerService } from 'src/app/services/service.index';
import { AccountManagerParams } from 'src/app/models/account-manager/account-manager-params';
import { AccountManagerResponse } from 'src/app/models/account-manager/account-manager-response';
import * as accountManager from '../actions/account-manager.actions';

@Injectable()
export class AccountManagerEffects {
  constructor(
    private _accountManagerService: AccountManagerService, 
    private actions$: Actions
  ) {
    // 
  }
 
  public loadAccountManager$ = createEffect(() =>
    this.actions$.pipe(
        ofType<accountManager.AccountManagerLoadAction>(accountManager.AccountManagerActionType.Loading),
        map((action) => action.payload),
        switchMap((params: AccountManagerParams) =>
        this._accountManagerService.getAllAccountManager().pipe(
          map((response : any) => new accountManager.AccountManagerLoadSuccessAction(response)),
          catchError((error) => of(new accountManager.AccountManagerLoadFailAction(error)))
        )
      )
    )
  );
}