import { createSelector, createFeatureSelector } from '@ngrx/store';
import { StateAccounts, accountsAdapter } from '../../store/states/accounts.state';

export const {
  selectIds: _selectAccountsDataIds,
  selectEntities: _selectAccountsEntities,
  selectAll: _selectAllAccounts,
  selectTotal: _selectAccountsTotal
} = accountsAdapter.getSelectors();

export const selectState = createFeatureSelector<StateAccounts>('accounts');

export const selectAccountsIds = createSelector(
  selectState,
  _selectAccountsDataIds
);

export const selectAccountsEntities = createSelector(
  selectState,
  _selectAccountsEntities
);

export const selectAllAccounts = createSelector(
  selectState,
  _selectAllAccounts
);

export const selectAccountsError = createSelector(
  selectState,
  (state: StateAccounts): boolean => state.error
);

export const selectAccountsLoading = createSelector(
  selectState,
  (state: StateAccounts): boolean => state.loading
);

export const selectAccountsTotal = createSelector(
  selectState,
  (state: StateAccounts): number => state.total
);
