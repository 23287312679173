import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SalesPoints } from '../../models/sales-points/sales-points';

export interface StateSalesPoints extends EntityState<SalesPoints> {
  error: boolean;
  loading: boolean;
  total: number;
}

export const salesPointsAdapter: EntityAdapter<SalesPoints> = createEntityAdapter<SalesPoints>({
  selectId: (salesPoints: SalesPoints) => salesPoints.puntoVentaId
});

export const initialStateSalesPoints: StateSalesPoints = salesPointsAdapter.getInitialState({
  error: false,
  loading: true,
  total: 0
});
