import * as customers from '../../store/states/customers.state';
import { CustomersAction, CustomersActionType } from '../../store/actions/customers.actions';

/**
 * All Customers
 */

export function customersReducer(
    state = customers.initialStateCustomers, 
    action: CustomersAction | any
  ): customers.StateCustomers {
  switch (action.type) {
    case CustomersActionType.Loading: {
      return { ...state, loading: true };
    }
    case CustomersActionType.LoadSuccess: {
      return customers.customersAdapter.setAll(action.payload.customers, {
        ...state,
        error: false,
        loading: false
      });
    }
    case CustomersActionType.LoadFailure: {
      return customers.customersAdapter.removeAll({
        ...state,
        error: true,
        loading: false
      });
    }
    default:
      return state;
  }
}

/**
 * Specific Customer by id
 */

export function customerReducer(
    stateCustomer = customers.initialStateCustomerById, 
    action: CustomersAction | any
  ): customers.StateCustomerById {
  switch (action.type) {
    case CustomersActionType.LoadingById: {
      return { ...stateCustomer, loading: true };
    }
    case CustomersActionType.LoadSuccessById: {
      return { ...stateCustomer,
        customer: action.payload.customers,
        error: false,
        loading: false
      };
    }
    case CustomersActionType.LoadFailureById: {
      return { ...stateCustomer,
        customer: {},
        error: true,
        loading: false
      };
    }
    default:
      return stateCustomer;
  }
}

