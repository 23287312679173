import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from './dashboard/dashboard.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ReceiptSaleComponent } from './receipt-sale/receipt-sale.component';
import { TaxesComponent } from './taxes/taxes.component';
import { SalariesComponent } from './salaries/salaries.component';
import { VoucherHistoryComponent } from './voucher-history/voucher-history.component';
import { EditVoucherComponent } from './edit-voucher/edit-voucher.component';
import { SalariesSheetloadComponent } from './salaries-sheetload/salaries-sheetload.component';
import { TaxesSheetloadComponent } from './taxes-sheetload/taxes-sheetload.component';
import { BulkLoadComponent } from './bulk-load/bulk-load.component';

const adminRoutes: Routes =[
    { path: '', component: DashboardComponent },
    { path: 'receipt', component: ReceiptComponent },
    { path: 'receipt-sale', component: ReceiptSaleComponent },
    { path: 'taxes', component: TaxesComponent },
    { path: 'salaries', component: SalariesComponent },
    { path: 'voucher-history', component: VoucherHistoryComponent },
    { path: 'edit-voucher/:voucherId', component: EditVoucherComponent },
    { path: 'salaries-sheetload', component: SalariesSheetloadComponent },
    { path: 'taxes-sheetload', component: TaxesSheetloadComponent },
    { path: 'bulk-load', component: BulkLoadComponent },
];

export const ADMIN_ROUTES = RouterModule.forChild( adminRoutes ); 